import { Injectable } from '@angular/core';
import { ResourceService } from '../core/services';

@Injectable()
export class ResourcesResolve {
  constructor(private resourceService: ResourceService) { }

  resolve() {
    //return this.resourceService.initResources();
  }
}
