import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FilePreview } from "./file-preview.model";
import { PreviewFileEnum } from "./preview-file.enum";

@Component({
  selector: "file-preview",
  templateUrl: "./file-preview.component.html",
  styleUrls: ["./file-preview.component.scss"],
})
export class FilePreviewComponent implements OnInit {
  pdfContentType = "application/pdf";

  isPDF: boolean;
  source: any;
  PreviewFileEnum = PreviewFileEnum;

  constructor(
    public dialogRef: MatDialogRef<FilePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilePreview
  ) {}

  ngOnInit() {
    if (this.data.type == PreviewFileEnum.HtmlLetter || this.data.type == PreviewFileEnum.Message) {
      this.source = this.data.file;
    } else {
      if (!this.data.isLocalFile) {
        this.isPDF = !!this.data.file.image == false;

        if (this.isPDF) {
          this.source = this.data.file.file;
        } else {
          this.source = this.data.file.image;
        }
      } else {
        this.isPDF = this.data.file.type == this.pdfContentType;

        this.source = this.data.file;
      }

      if (this.isPDF) {
        this.data.type = PreviewFileEnum.Pdf;
      } else {
        this.data.type = PreviewFileEnum.Image;
      }
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
