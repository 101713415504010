import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function ValidateEmail(inputControl: FormControl): ValidationErrors | null {
  if (inputControl.value && inputControl.value.length > 0) {
    let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regex.test(inputControl.value) ? null : { emailInvalid: true };
  }

  return null;
}


export function EmailInvalidValidatorMessage(err, field: FormlyFieldConfig): string {
  return `${field.templateOptions.label} is not valid`;
}
