import { Injectable, EventEmitter } from '@angular/core';
import { LoadingStepsEnum } from 'src/app/shared/components/loading/loading-steps.enum';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loadingStep: EventEmitter<LoadingStepsEnum> = new EventEmitter<LoadingStepsEnum>();
  private requestCount: number = 0;

  constructor() { }

  setLoadingIndicatorStep(loadingStep: LoadingStepsEnum) {
    if (loadingStep == LoadingStepsEnum.Loading) {
      this.requestCount++;

      if(this.requestCount == 1) {
        this.loadingStep.emit(loadingStep);
      }
    }
    else if (loadingStep == LoadingStepsEnum.Content) {
      if(this.requestCount > 0) {
        this.requestCount--;
      }

      if(this.requestCount == 0) {
        this.loadingStep.emit(loadingStep);
      }
    }
  }
}
