import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { AuthenticationService } from "../services/authentication.service";
import { ActivatedRoute } from "@angular/router";
import { EnvironmentConfigsLoaderService } from "../services/environment-configs-loader.service";
import { LoadingService } from "../services/loading.service";
import { LoadingStepsEnum } from "src/app/shared/components/loading/loading-steps.enum";
import { finalize } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private screenerWebUrl: string;

  constructor(
    environmentConfigs: EnvironmentConfigsLoaderService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.screenerWebUrl = environmentConfigs.ScreenerWebUrl;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.access_token) {
      let requestUrl = "";

      if (request.url.indexOf('assets') >= 0) {
        requestUrl = location.origin + location.pathname + request.url;

        request = request.clone({
          url: requestUrl
        });
      }
      else if (currentUser.tenantCode) {
        requestUrl = `/${this.screenerWebUrl}/${currentUser.tenantCode}/${request.url}`;
      } else {
        requestUrl = `/${this.screenerWebUrl}/${request.url}`;
      }

      request = request.clone({
        url: requestUrl,
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`,
        },
      });
    } else if (request.url.indexOf("token") < 0) {
      let tenantCodePath = this.readTenantCodeFromRoute();

      request = request.clone({
        url: `/${this.screenerWebUrl}/${tenantCodePath + request.url}`,
      });
    } else {
      request = request.clone({
        url: `/${this.screenerWebUrl}/${request.url}`,
      });
    }

    this.loadingService.setLoadingIndicatorStep(LoadingStepsEnum.Loading);
    return next.handle(request).pipe(
      finalize(() => {
        this.loadingService.setLoadingIndicatorStep(LoadingStepsEnum.Content);
      })
    );
  }

  private readTenantCodeFromRoute(): string {
    if (this.route.firstChild && this.route.firstChild.firstChild && this.route.firstChild.firstChild.snapshot) {
      let tenantCode = this.route.firstChild.firstChild.snapshot.paramMap.get(
        "tenantCode"
      );
      return tenantCode ? tenantCode + "/" : "";
    }
    return "";
  }
}
