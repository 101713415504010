import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [LoadingComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  exports: [LoadingComponent]
})
export class LoadingModule { }
