<div *ngIf="!isNewTemplate" class="navbar">

  <a routerLink="{{ homepageLink }}" [ngClass]="{ 'disable': disableLogo }"
    [ngClass]=" isNewTemplate ? 'margin-left-logo' : ''"><img [src]="logoPath" class="logo" /></a>
  <button *ngIf="!loggedIn && btnNotInLoginPage" type="submit" class="d-block d-xl-none outline btn-signin"
    (click)="getVisibility()">
    {{ btnLoginText }}
  </button>

  <div *ngIf="isNewTemplate && isParentPortal" class="parent-portal-title-div">
    <mat-label class="parent-portal-title"> {{'Parent_Portal' | resourcekey }}</mat-label>
  </div>
  <div *ngIf="isNewTemplate && isClassSessionPortal && !loggedIn" class="parent-portal-title-div">
    <mat-label class="parent-portal-title"> {{'Self_Compoletion_Portal' | resourcekey }}</mat-label>
  </div>
  <div *ngIf="isNewTemplate && isPublicSelfPortal && !loggedIn" class="parent-portal-title-div">
    <mat-label class="parent-portal-title"> {{'Public_Self_Portal' | resourcekey }}</mat-label>
  </div>
  <mat-icon *ngIf="loggedIn" class="menu-icon notification" matBadge={{totalCount}} matBadgeColor="warn"
    aria-hidden="false" [matBadgeHidden]="!isNotificationVisible" (click)="changeUserCornerVisibility()">menu
  </mat-icon>

  <div *ngIf="loggedIn" class="user-corner">
    <div class="user-corner-container" [hidden]="isUserCornerHidden">
      <mat-toolbar *ngIf="isNotificationVisible">
        <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="totalCount > 0 ? clickmenu : null">
          <mat-icon class="notification" matBadge={{totalCount}} matBadgeColor="warn">notifications_none
          </mat-icon>
        </button>
      </mat-toolbar>
      <mat-menu #clickmenu="matMenu" style="padding-top: 15px; max-width: 420px !important;">
        <div style="padding-top: 15px; max-width: 420px !important;">
          <button mat-menu-item *ngFor="let message of messageList" (click)="clickMenuItem(message)"
            style="margin-bottom:5px; height: auto">
            <div class="row" style="line-height: initial; margin: 2px 2px 2px 2px;">
              <div class="col-7 col-xs-7 col-sm-7 col-md-7">
                <div class="row">
                  <div class="col-sm-12 col-md-12" style="font-size: 12px; font-weight: bold;  padding-left: 0px; overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;">
                    <span>{{ 'Display_Re' | resourcekey }} {{message.childFullName }}</span>
                  </div>
                  <div class="col-sm-12 col-md-12" style="font-size: 9px;  padding-left: 0px;">
                    <span> {{ 'Display_SentOn' | resourcekey }}
                      {{message.sharedDocumentSource.createdOnString}} </span>
                  </div>
                </div>
              </div>
              <div class="col-5 col-xs-5 col-sm-5 col-md-5" style="overflow:hidden;  padding-right: 0px;">
                <img mat-card-sm-image style="width: auto; max-width: 99%; max-height: 30px; float:right;"
                  class="notificationItem" src="{{message.tenantLogo}}" alt="Client Logo">
              </div>
            </div>
            <div class="row" style="font-size: 10px; line-height: initial;">
              <div class="col-sm-12 col-md-12" style="overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap; margin-left: 2px;">
                <span> {{message.sharedDocumentSource.sharedDocument.title}} </span>
              </div>
            </div>
            <mat-divider style="margin-top: 2px;"></mat-divider>
          </button>
        </div>
      </mat-menu>
      <button mat-flat-button [matMenuTriggerFor]="!isClassSessionPortal ? menu : null" matTooltip="{{ email }}">
        {{
        'Common_Hi' | resourcekey }}, {{ userName }} </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="userProfile()">{{ 'Common_UserProfile' | resourcekey }}</button>
        <button mat-menu-item (click)="logout()">{{ 'Common_Logout' | resourcekey }}</button>
      </mat-menu>
      <button *ngIf="isAdminButtonVisible" mat-raised-button (click)="onSwitchButton()" class="admin-button">
        {{ switchButtonText }}
      </button>
      <button mat-raised-button color="primary" (click)="logout()" class="logout-button">
        {{ 'Common_Logout' | resourcekey }}
      </button>
    </div>
  </div>
</div>
<div *ngIf="isNewTemplate" class="navbar navbar-parent">
  <a routerLink="{{ homepageLink }}" [ngClass]="{ 'disable': disableLogo }" class="margin-left-logo"><img
      [src]="logoPath" class="logo" /></a>
  <button *ngIf="!loggedIn && btnNotInLoginPage" type="submit" class="d-block d-xl-none outline btn-signin"
    (click)="getVisibility()">
    {{ btnLoginText }}
  </button>

  <div *ngIf="isParentPortal && !loggedIn" class="parent-portal-title-div">
    <mat-label class="parent-portal-title"> {{'Parent_Portal' | resourcekey }}</mat-label>
  </div>
  <div *ngIf="isClassSessionPortal && !loggedIn" class="parent-portal-title-div">
    <mat-label class="parent-portal-title"> {{'Self_Compoletion_Portal' | resourcekey }}</mat-label>
  </div>
  <div *ngIf="isPublicSelfPortal && !loggedIn" class="parent-portal-title-div">
    <mat-label class="parent-portal-title"> {{'Public_Self_Portal' | resourcekey }}</mat-label>
  </div>

  <div *ngIf="!isMobile && loggedIn" class="mat-tab-container">
    <mat-tab-group [(selectedIndex)]="selectedHeaderTabIndex" animationDuration="0ms"
      (selectedTabChange)="tabChanged($event)">
      <mat-tab label="{{ 'Common_Forms' | resourcekey }}"> </mat-tab>
      <mat-tab label="{{ 'Common_Inbox' | resourcekey }}"><ng-template mat-tab-label> <mat-icon class="inbox-tab-icon">
            mail_outline</mat-icon> {{ 'Common_Inbox' | resourcekey }} <mat-icon class="inbox-tab-badge"
            matBadge={{totalCount}} matBadgeColor="warn"></mat-icon></ng-template></mat-tab>
    </mat-tab-group>
  </div>

  <div *ngIf="loggedIn" class="user-corner">
    <mat-icon *ngIf="isMobile" class="menu-icon notification" matBadge={{totalCount}} matBadgeColor="warn"
      aria-hidden="false" [matMenuTriggerFor]="!isClassSessionPortal ? menu : null">menu
    </mat-icon>
    <button class="menu-button-web" *ngIf="!isMobile" aria-hidden="false"
      [matMenuTriggerFor]="!isClassSessionPortal ? menu : null"><span class="username-text"> {{ userName }} </span>
      <mat-icon class="menu-icon-web">menu</mat-icon> </button>
    <div class="user-corner-container-new">
      <!-- <mat-toolbar *ngIf="isNotificationVisible">
          <button mat-icon-button (click)="goToInbox()">
            <mat-icon class="notification" matBadge={{totalCount}} matBadgeColor="warn">notifications_none
            </mat-icon>
          </button>
        </mat-toolbar> -->
      <!-- <button mat-flat-button [matMenuTriggerFor]="!isClassSessionPortal ? menu : null" matTooltip="{{ email }}"> {{
          'Common_Hi' | resourcekey }}, {{ userName }} </button> -->
      <mat-menu #menu="matMenu" class="mat-menu-group">
        <div class="mat-menu-container">
          <div class="inbox-menu-item" mat-menu-item *ngIf="isParentPortal && isMobile">
            <button matTooltip="{{ email }}">
              <mat-icon> sentiment_satisfied_alt</mat-icon>
              <b>{{'Common_Hi' | resourcekey }}, {{ userName }} </b>
            </button>
          </div>

          <mat-divider class="divider"></mat-divider>

            <div class="inbox-menu-item" mat-menu-item *ngIf="isParentPortal && isMobile"  (click)="goToForms()">
            <button>
              <mat-icon>filter_none</mat-icon>
              {{ 'Common_Forms' | resourcekey }}
            </button>
          </div>

            <div class="inbox-menu-item" mat-menu-item *ngIf="isParentPortal && isMobile"  (click)="goToInbox()">
            <button>
              <mat-icon>mail_outline</mat-icon>
              {{ 'Common_Inbox' | resourcekey }}
            </button>
            <div class="unread-messages-count">{{totalCount}}</div>
          </div>

          <div class="inbox-menu-item" mat-menu-item  (click)="userProfile()">

            <button>
              <mat-icon fontIcon="home"></mat-icon>
              {{ 'Common_UserProfile' | resourcekey }}
            </button>
          </div>

          <div class="inbox-menu-item" mat-menu-item (click)="logout()">
            <button>
              <mat-icon>power_settings_new</mat-icon>
              {{ 'Common_Logout' | resourcekey }}
            </button>
          </div>
        </div>
      </mat-menu>
      <button *ngIf="isAdminButtonVisible" mat-raised-button (click)="onSwitchButton()" class="admin-button">
        {{ switchButtonText }}
      </button>
    </div>
  </div>
</div>