import { Injectable } from "@angular/core";
import { KeyValuePair, ResourceFilter } from 'src/app/shared/models';
import { HttpCoreService } from "./http/httpcore.service";
import { AuthenticationService } from "./authentication.service";
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ResourceService {

  constructor() { }

  // initResources() {

  //   this.authServiceSubscription = this.authenticationService.currentUser.subscribe(user => {
  //     this.getAllResources();
  //   })

  //   this.getAllResources();
  // }

  getResourceByKey(key: string) {
    let tmp = JSON.parse(sessionStorage.getItem("resources"));
    if(!this.resourcesFromServer) {
      this.resourcesFromServer = tmp;
    }
    else if(!tmp) {
      sessionStorage.setItem("resources", JSON.stringify(this.resourcesFromServer));
    }

    let locale = sessionStorage.getItem("locale");
    if(locale && locale == '"***"') {
      return key;
    }
    else {
      return this.resourcesFromServer[key.toLowerCase()];
    }
  }

    // getAllResources() {
  //   let filter: ResourceFilter = { culture: this.locale != '' && this.locale != null ? this.locale : 'en-GB', domainName: '', tenantCode: '', branding: this.brand };

  //   this.httpService.services.resources.getTextResources(filter).subscribe((data: any) => {
  //     this.resourcesFromServer = data;
  //   });
  // }

  public cleanResourceCache() {
    this.resourcesFromServer = null;
  }

  private resourcesFromServer: KeyValuePair<string>;
}
