import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UrlSanitizer {

  constructor(private domSanitizer: DomSanitizer) {}

  sanitizeUrl(url: string) {
    let sanitized = this.domSanitizer.sanitize(SecurityContext.URL, url);

    return this.domSanitizer.bypassSecurityTrustResourceUrl(sanitized);
  }
}
