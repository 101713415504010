import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { CovidMarkTestedModel } from 'src/app/shared/components/covid-mark-tested-dialog/covid-mark-tested.model';

@Injectable({
  providedIn: ServicesModule,
})
export class HttpCovidTestService {
  constructor(private http: HttpClient) { }

  saveCovidMarkTestedTest(markTestedModel: CovidMarkTestedModel) {
    return this.http.post('api/covid/marktested/test', markTestedModel);
  }

  uploadMarkTestedTestPhoto(photo: any) {
    return this.http.post('api/covid/marktested/test/upload/photo', { covidPhoto: photo });
  }

  getChildCovidTestsWithTestColumnDetails(userRelationshipId: string, pageSize: number, pageIndex: number) {
    return this.http.get(`api/child/${userRelationshipId}/covidtests/${pageSize}/${pageIndex}`);
  }

  getCovidMarkTestedOptionsForDialog(childId: string) {
    return this.http.get(`api/covid/marktested/dialog/getOptions/${childId}`);
  }

  getCovidTestPhotosByCovidTestId(covidTestId: string) {
    return this.http.get(`api/covid/test/photos/${covidTestId}`);
  }
}
