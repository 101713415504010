import { Pipe, PipeTransform } from '@angular/core';
import { ResourceService } from 'src/app/core/services';

@Pipe({
  name: 'visionAcuityScaleType'
})

export class VisionAcuityScaleTypePipe implements PipeTransform {

  resources: string[];

  constructor(
    private resourceService: ResourceService,
  ) {
    var resources = {
      0: this.resourceService.getResourceByKey("VisionTest_LogmarVa"),
      1: this.resourceService.getResourceByKey("VisionManualTestSnellenUk"),
      2: this.resourceService.getResourceByKey("VisionManualTestSnellenUs"),
    };
  }

  transform(input: number): any {
    if (!this.resources) { }
    if (input == undefined) {
      return '';
    } else {
      switch (input) {
        case 0:
          return this.resourceService.getResourceByKey("VisionTest_LogmarVa");
        case 1:
          return this.resourceService.getResourceByKey("VisionManualTestSnellenUk");
        case 2:
          return this.resourceService.getResourceByKey("VisionManualTestSnellenUs");
      }

    }
  }
}
