import { Pipe, PipeTransform } from '@angular/core';
import { ResourceService } from 'src/app/core/services';
import { GenderEnum } from '../enums/gender.enum';

@Pipe({
  name: 'valueNotAvailable'
})
export class ValueNotAvailable implements PipeTransform {

  constructor(
    private resourceService: ResourceService,
  ) { }

  transform(value: string): string {
    if (value) {
      return value;
    }
    return this.resourceService.getResourceByKey("Common_NotAvailable_Short");
  }


}
