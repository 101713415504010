import { NgModule } from "@angular/core";
import { Routes, RouterModule, Route } from "@angular/router";
import { LogoHnaResolve } from "./logo-hna.resolve";
import { LogoImmsResolve } from "./logo-imms.resolve";
import { LogoScreenerResolve } from "./logo-screener.resolve";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { RolesEnum } from "../shared/enums/roles.enum";
import { LogoClinicianResolve } from "./logo-clinician.resolve";
import { EthniesResolve } from "../shared/resolves";


const landingPageRoute: Route = {
  path: "login",
  canActivate: [AuthGuard],
  loadChildren: () =>
    import("../features/landing-page/landing-page.module").then(
      (mod) => mod.LandingPageModule
    ),
};

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "login",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../features/landing-page/landing-page.module").then(
            (mod) => mod.LandingPageModule
          ),
      },
      {
        path: "mfa",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../features/screener/mfa-login-page/mfa-login-page.module").then(
            (mod) => mod.MfaLoginPageModule
          ),
      },
      {
        path: "forgot-password",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../shared/components/forgot-password-page/forgot-password-page.module").then(
            (mod) => mod.ForgotPasswordPageModule
          ),
      },
      {
        path: "reset-password",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../shared/components/reset-password-page/reset-password-page.module").then(
            (mod) => mod.ResetPasswordPageModule
          ),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("../features/parent-portal/parent-portal.module").then(
            (mod) => mod.ParentPortalModule
          ),
        resolve: { logo: LogoImmsResolve },
      },
      {
        path: "inbox",
        loadChildren: () =>
          import("../features/parent-portal/parent-inbox/parent-inbox.module").then(
            (mod) => mod.ParentInboxModule
          ),
        resolve: { logo: LogoImmsResolve },
      },
      {
        path: "clinician",
        loadChildren: () =>
          import("../features/screener/clinician/clinician.module").then(
            (mod) => mod.ClinicianModule
          ),
        resolve: { logo: LogoClinicianResolve },
      },
      {
        path: ":tenantCode/login",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../features/landing-page/landing-page.module").then(
            (mod) => mod.LandingPageModule
          ),
      },
      {
        path: ":tenantCode/HNA",
        loadChildren: () =>
          import(
            "../features/public-self-portal/public-self-portal.module"
          ).then((mod) => mod.PublicSelfPortalModule),
        // resolve: { logo: LogoHnaResolve },
      },
      {
        //canLoad: [CampaignValidationGuard],
        path: ":tenantCode/imms/:campaigncode",
        loadChildren: () =>
          import(
            "../features/campaign-page/campaign-page.module"
          ).then((mod) => mod.CampaignPageModule),
          resolve: { logo: LogoImmsResolve,
                     ethnies: EthniesResolve,
           },
      },
      {
        //canLoad: [CampaignValidationGuard],
        path: ":tenantCode/p/:campaigncode",
        loadChildren: () =>
          import(
            "../features/campaign-page/campaign-page.module"
          ).then((mod) => mod.CampaignPageModule),
          resolve: { logo: LogoHnaResolve,
                     ethnies: EthniesResolve,
           },
      },
      {
        path: ":tenantCode/g/:code",
        loadChildren: () =>
          import(
            "../features/public-parent-page/public-parent-page.module"
          ).then((mod) => mod.PublicParentPageModule),
      },
      {
        path: ":tenantCode/b/:code",
        loadChildren: () =>
          import(
            "../features/public-parent-page/public-parent-page.module"
          ).then((mod) => mod.PublicParentPageModule),
      },
      {
        path: ":tenantCode/pr/:code",
        loadChildren: () =>
          import(
            "../features/public-parent-page/public-parent-page.module"
          ).then((mod) => mod.PublicParentPageModule),
      },
      {
        path: ':tenantCode/Account',
        loadChildren: () => import('../features/screener/clinician/clinician.module').then(mod => mod.ClinicianModule)
      },
      {
        path: "manage-user",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../features/manage-user/manage-user.module").then(
            (mod) => mod.ManageUserModule
          ),
      },
      {
        path: "",
        canActivate: [AuthGuard],
        data: {
          roles: [
            RolesEnum.Screener,
            RolesEnum.Administrator,
            RolesEnum.TenantAdministrator,
            RolesEnum.ReadonlyReporting,
            RolesEnum.ReadonlyKPIUser,
            RolesEnum.Clinician
          ],
        },
        loadChildren: () =>
          import("../features/screener/screener.module").then(
            (mod) => mod.ScreenerModule
          ),
        resolve: { logo: LogoScreenerResolve },
      },
      {
        path: "",
        pathMatch: "full",
        redirectTo: "login",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class RoutingModule {}
