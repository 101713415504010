<div class="container-gdprPopup" *ngIf="gdprNotificationDisplayed">
  <div class="gdpr-notification">
    <div class="inline-element">
      <div class="gdpr-notification-text-header inline-element">{{ 'GDPRPolicy_Title' | resourcekey }}</div>
      <div class="gdpr-notification-text inline-element"> 
        {{ 'GDPRPolicy_Text' | resourcekey }}
        <span (click)="gdprSeen()" class="close-word"> {{ 'Common_Close' | resourcekey }}</span>
      </div>
    </div>
  </div>
</div>
