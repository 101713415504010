import { QueueTypeEnum } from '../enums/queue-type.enum';
import { Letter } from './letter.model';

export interface ChildPdfTableItemModel {
  id: string;
  createdOn: Date;
  letterType: string;
  firstName: string;
  lastName: string;
  class: string;
  school: string;
  childPdfWithContent?: Letter;
  isShared: boolean;
  queueType: QueueTypeEnum;
  yearGroup: string;
}