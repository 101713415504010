export interface User {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  town?: string;
  county?: string;
  postCode?: string;
}
