import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { HNAStatesEnum } from '../../../shared/enums/hna-states.enum';
import { QuestionResultFlagTypeEnum } from '../../../shared/enums/question-result-flag-type.enum';

@Injectable({
  providedIn: ServicesModule
})

export class HttpHNAService {

  constructor(private http: HttpClient) { }

  addHNATest(HNATest, sessionCode) {
    return this.http.post('api/' + sessionCode + '/hnaTest/add', HNATest);
  }

  getCampaignBySessionId(sessionId: string) {
    return this.http.get('api/getSessionCampaign/' + sessionId);
  }

  getHnaQuestionnaireDetails(sessionId: string) {
    return this.http.get('api/hnaTest/getHnaQuestionnaireDetails/' + sessionId);
  }

  getCampaignQuestionnaireBySessionId(sessionId: string) {
    return this.http.get('api/hnaTest/getCampaignQuestionnaire/' + sessionId);
  }

  getSessions(campaignId: string, schoolId: string) {
    return this.http.get('api/getSessions/' + campaignId + '/' + schoolId);
  }

  getMatchedChildren(sessionId: string) {
    return this.http.get('api/getMatchedChildren/' + sessionId);
  }

  getHNAQuestionnaireResult(hnaTestId: string) {
    return this.http.get('api/hnaQuestionnaireResult/' + hnaTestId);
  }

  getHNATabDetails(hnaTestId: string) {
    return this.http.get('api/hna/getHNATabDetails/' + hnaTestId);
  }

  getCovidTabDetails(covidTestId: string) {
    return this.http.get('api/hna/getCovidTabDetails/' + covidTestId);
  }

  resolveHNAQuestion(questionResultId: string, comment: string, sourceId: string) {
    return this.http.post('api/hna/resolve/question', { questionResultId, comment, sourceId });
  }

  unresolveHNAQuestion(questionResultId: string, sourceId: string) {
    return this.http.post('api/hna/unresolve/question', { questionResultId, sourceId });
  }

  totalResolveHNAQuestions(sourceId: string, flagType: QuestionResultFlagTypeEnum, comment: string) {
    return this.http.post('api/hna/totalresolve/question', { sourceId, flagType, comment });
  }

  addNeedsAttention(hnaTestId: string, comment: string) {
    return this.http.post('api/hna/addNeedsAttention', {
      hnaTestId: hnaTestId,
      ReasonComment: comment
    });
  }

  resolveNeedsAttention(hnaTestId: string, comment: string) {
    return this.http.post('api/hna/resolveNeedsAttention', {
      hnaTestId: hnaTestId,
      ResolveComment: comment
    });
  }

  lockHNATest(hnaTestId: string) {
    return this.http.post('api/lock/hna/' + hnaTestId, null);
  }

  getHNATestsListByChildIdAndCampaignId(childId: string, campaignId: string, pageSize: number, pageIndex: number) {
    return this.http.get('api/hna/tests/' + childId + '/' + campaignId + '/' + pageSize + '/' + pageIndex);
  }

  getSchoolNameCodeBySessionCode(sessionCode: string, campaignId: string) {
    return this.http.get("api/hna/GetSchoolNameCodeBySessionCode/" + sessionCode + '/' + campaignId);
  }
}
