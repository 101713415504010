import { Component, OnInit, Input } from '@angular/core';
import { FileService } from '../../../core/services/file.service';

@Component({
  selector: 'pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit {
  @Input() isLocalFile: boolean;
  @Input() pdf: any;
  pdfSrc: any;

  constructor(private fileService: FileService) { }

  ngOnInit() {
    if (this.isLocalFile) {
      this.previewPDF();
    }
    else {
      this.existingPreviewPDF();
    }
  }

  previewPDF() {
    let reader = new FileReader();

    reader.onload = (e: any) => {
      this.pdfSrc = e.target.result;
    };

    reader.readAsArrayBuffer(this.pdf);
  }

  existingPreviewPDF() {
    this.pdfSrc = this.fileService.convertDataToBinary(this.pdf);
  }
}
