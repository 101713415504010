import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { PrintExportQueueModel } from 'src/app/features/screener/children-page/letter-template-dialog/print-export-queue.model';
import { PrintExportQueueFilterOptions } from 'src/app/features/screener/print-queue/print-export-queue-filter-options.model';
import { ExportDownloadModel } from 'src/app/features/screener/shared/components/data-extract-dialog/export-download.model';
import { ProcessItems } from 'src/app/features/screener/print-queue/process-items-dialog/process-items.model';
import { QueueFileDownloadOptionsTypes } from 'src/app/features/screener/shared/enums';
import { DataSortTypeEnum } from '../../../features/screener/shared/enums/data-sort-type.enum';

@Injectable({
  providedIn: ServicesModule
})

export class HttpLetterService {

  constructor(private http: HttpClient) { }

  downloadLetter(templateId: string, childId: string) {
    return this.http.get('api/downloadhnaletter/' + templateId + '/' + childId);
  }

  downloadLetterBasedOnSubmission(submissionId: string) {
    return this.http.get('api/downloadhnaletter/portal/' + submissionId);
  }

  downloadPdfContentLetter(pdfContentId: string) {
    return this.http.get('api/downloadpdfcontent/' + pdfContentId);
  }

  downloadPdfContentLetterByChildPdfId(tenantId: string, childPdfId: string) {
    return this.http.get('api/download/pdfcontent/' + tenantId + '/' + childPdfId);
  }

  // getPrintQueueLetters(schoolId: string,filterOptions: PrintExportQueueFilterOptions){
  //   let params = new HttpParams();

  //   for (let f of Object.keys(filterOptions)) {
  //       params = params.append(`${f}`, filterOptions[f]);
  //   }

  //   return this.http.get(`api/schools/${schoolId}/printQueue`, { params: params });
  // }

  getPrintQueueLetters(filterOptions: PrintExportQueueFilterOptions) {
    return this.http.post("api/children/printQueue", filterOptions);
  }

    saveBulkPrintExportQueue(printExportQueueModel: PrintExportQueueModel) {
      return this.http.post("api/saveBulk/print/export/queue", printExportQueueModel);
    }

    getPrintExportQueueDropdownFilters() {
      return this.http.get('api/print-export/queue/dropdown/filters');
    }

  getPrintQueueLetterById(childPdfId: string) {
    return this.http.get(`api/printQueue/${childPdfId}`);
  }

  shareLetters(letterIds: string[], printExportQueueFilterOptions:PrintExportQueueFilterOptions = null) {
    return this.http.post("api/shareLetters", { letterIds, printExportQueueFilterOptions });
  }

  unshareLetters(letterIds: string[], printExportQueueFilterOptions:PrintExportQueueFilterOptions = null) {
    return this.http.post("api/unshareLetters", { letterIds, printExportQueueFilterOptions });
  }

  removeLettersFromPrintQueue(letterIds: string[], printExportQueueFilterOptions:PrintExportQueueFilterOptions = null) {
    return this.http.post("api/removeLetters", { letterIds, printExportQueueFilterOptions });
  }

  downloadLetters(data) {
    return this.http.post("api/downloadLetters", data);
  }

  generatePrintQueueDocumentsAndAddToQueue(data) {
    return this.http.post("api/generatePrintQueueDocumentsAndAddToQueue", data);
  }

  getChildLetters(userRelationshipId: string) {
    return this.http.get(`api/parent/child/letters/${userRelationshipId}`);
  }

  getLetterContent(childPdfId: string) {
    return this.http.get(`api/parent/child/getLetterContent/${childPdfId}`);
  }

  downloadExportListRoster(data: ExportDownloadModel) {
    return this.http.post("api/export-list/download/roster", data);
  }

  downloadExportListMailMerge(data: ExportDownloadModel) {
    return this.http.post("api/export-list/download/mail-merge", data);
  }

  getSchoolFolderDocumentsList(schoolIds: string) {
    return this.http.post(`api/school-folder/documents-list`, {schoolIds});
  }

  downloadSchoolFolderRosterList(schoolIdList: string, pageSize: number, dataSortType: DataSortTypeEnum) {
    return this.http.post(`api/school-folder/download/export/roster-list`, {pageSize, dataSortType, schoolIdList});
  }

  downloadSchoolFolderMailMergeList(schoolIdList: string, pageSize: number, dataSortType: DataSortTypeEnum) {
    return this.http.post(`api/school-folder/download/export/mailmerge-list`, {pageSize, dataSortType, schoolIdList});
  }

  downloadSchoolFolderLetters(schoolIds: string, templateId: string, includeTableOfContents: boolean, individualBySchool: boolean, templateIds: string[], sortType: DataSortTypeEnum) {
    if(individualBySchool) {
      let options = {};
      options['responseType'] = 'arraybuffer';
      return this.http.post(`api/school-folder/download/template/${templateId}`, {
        schoolIds,
        templateId,
        includeTableOfContents,
        individualBySchool,
        templateIds,
        sortType
      },options);
    }
    else {
      return this.http.post(`api/school-folder/download/template/${templateId}`, {
        schoolIds,
        templateId,
        includeTableOfContents,
        individualBySchool,
        templateIds
      });
    }
   
  }

  processPrintQueueItems(processItems: ProcessItems) {
    let options = {};
    if (processItems.download && processItems.downloadOption == QueueFileDownloadOptionsTypes.IndividualFilesInZip) {
      options['responseType'] = 'arraybuffer';
    }

    return this.http.post(`api/process-items/print-queue`, processItems, options);
  }

  processPrintQueueItemsAddQueue(data: ProcessItems) {
    return this.http.post("api/processPrintQueueItemsAddQueue", data);
  }

  getQueuedDocumentList() {
    return this.http.get("api/print-queue/getQueuedDocuments");
  }

  downloadQueuedDocument(id: string, isZip: boolean) {
    let options = {};
    if(isZip) {
      options['responseType'] = 'arraybuffer';
    }
    return this.http.post(`api/print-queue/download-queued-document/${id}`,null, options);
  }

  deleteQueuedDocument(id: string) {
    return this.http.post(`api/print-queue/delete-queued-document/${id}`, null);
  }
}
