import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snellenValue'
})
export class SnellenValuePipe implements PipeTransform {

  transform(input: number, type: number): any {
      if (type == 0) return input;

      switch (input) {
          case 0.1:
              return type == 1 ? '6/7.6' : '20/25';
          case 0.125:
              return type == 1 ? '6/8' : '20/27';
          case 0.15:
              return type == 1 ? '6/8.5'  : '20/25';
          case 0.175:
              return type == 1 ? '6/9'  : '20/30';
          case 0.2:
              return type == 1 ? '6/9.5'  : '20/32';
          case 0.225:
              return type == 1 ? '6/10.1'  : '20/34';
          case 0.25:
              return type == 1 ? '6/10.7'  : '20/36';
          case 0.275:
              return type == 1 ? '6/11.3'  : '20/38';
          case 0.3:
              return type == 1 ? '6/12'  : '20/40';
          case 0.325:
              return type == 1 ? '6/12.7'  : '20/42';
          case 0.35:
              return type == 1 ? '6/13.4'  : '20/45';
          case 0.375:
              return type == 1 ? '6/14.2'  : '20/47';
          case 0.4:
              return type == 1 ? '6/15.1'  : '20/50';
          case 0.425:
              return type == 1 ? '6/16'  : '20/53';
          case 0.45:
              return type == 1 ? '6/16.9'  : '20/56';
          case 0.475:
              return type == 1 ? '6/17.9'  : '20/60';
          case 0.5:
              return type == 1 ? '6/19'  : '20/63';
          case 0.525:
              return type == 1 ? '6/20.1'  : '20/67';
          case 0.55:
              return type == 1 ? '6/21.3'  : '20/71';
          case 0.575:
              return type == 1 ? '6/22.6'  : '20/75';
          case 0.6:
              return type == 1 ? '6/23.9'  : '20/80';
          case 0.625:
              return type == 1 ? '6/25.3'  : '20/84';
          case 0.65:
              return type == 1 ? '6/26.8'  : '20/89';
          default:
              return input.toString();
      }

  }

}
