import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import * as moment from "moment";
import { Subscription } from 'rxjs';
import { ListItem } from '../../models/list-item.model';
import { CheckNull } from '../../validators';
import { ResourceService } from 'src/app/core/services';

@Component({
  selector: 'input-datepicker',
  templateUrl: './input-datepicker.component.html',
  styleUrls: ['./input-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatepickerComponent),
      multi: true
    }
  ]
})
export class InputDatepickerComponent implements ControlValueAccessor, OnDestroy {
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() isExactDate: boolean = true;
  @Input() isRequiredVisible: boolean = true;
  @Input() isRedRequired: boolean = false;


  datepickerForm: FormGroup;
  currenDate: Date = new Date();

  days: ListItem<number>[] = [{ label: '', value: null }];
  months: ListItem<number>[] = [{ label: '', value: null }];
  years: number[] = [null];
  hasInitialValue: boolean = false;
  selectedDay: number;
  selectedMonth: number;
  selectedYear: number;
  requiredLabel: string;

  private _february  = 1;
  private _value: Date;
  private _valueUnknown: string;

  onChanged: any = () => { };
  onTouched: any = () => { };

  formSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, protected resourceService: ResourceService) { }

  writeValue(value: Date): void {
    this.hasInitialValue = !!value;

    if (this.hasInitialValue) {
      this._value =  new Date(value);
      if(this._value.toString() == 'Invalid Date') {
        this._valueUnknown = value.toString();
      }
    }

    this.onChanges();

    this.createDatepickerForm();

    this.initDropdownsValue();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  createDatepickerForm() {
    this.requiredLabel = this.isRedRequired ? "* ( " + this.resourceService.getResourceByKey("Common_Required") + " )" : " *";
    if(this.isRequiredVisible && !this.isRedRequired) {
      this.label = this.label + "*";
    }
    if (this.hasInitialValue) {
      if(this._value.toString() != 'Invalid Date') {
        this.selectedDay = this._value.getDate();
        if(this.hasInitialValue && !this.isExactDate) {
          this.selectedMonth = this._value.getMonth() + 1;
        }
        else {
          this.selectedMonth = this._value.getMonth();
        }
        this.selectedYear = this._value.getFullYear();
      }
      else {
        let strList: string[] = this._valueUnknown.split("-",3);
        if(strList.length == 3) {
          this.selectedYear = Number(strList[0]);
          this.selectedMonth = Number(strList[1]);
          this.selectedDay = Number(strList[2]);
        }
      }
    }

    this.datepickerForm = this.formBuilder.group({
      day: [
        this.hasInitialValue ? this.selectedDay : '',
        [Validators.required, CheckNull],
      ],
      month: [
        this.hasInitialValue ? this.selectedMonth : '',
        [Validators.required, CheckNull],
      ],
      year: [
        this.hasInitialValue ? this.selectedYear : '',
        [Validators.required, CheckNull]
      ]
    });

    this.listenFormChanges();
  }

  initDropdownsValue() {
    this.generateDaysAndSetDayValue();

    this.generateMonths();

    this.generateYears();
  };

  generateDaysAndSetDayValue() {
    let defaultDate: Date =  new Date(this.currenDate.getFullYear(), this.currenDate.getMonth(), 1);

    if(this.selectedMonth) {
      if(this.isExactDate) {
        defaultDate.setMonth(this.selectedMonth);
      }
      else {
        defaultDate.setMonth(this.selectedMonth - 1);
      }

    }

    if(this.selectedYear) {
      defaultDate.setFullYear(this.selectedYear);
    }

    let daysInMonth = 31;
    if(this.selectedMonth && this.selectedYear) {
      daysInMonth = moment(defaultDate, "YYYY-MM").daysInMonth();
    }

    if(this.days.length == 1) {
      if(this.isExactDate) {
        this.generateNumbersList(this.days, 1, daysInMonth);
      }
      else {
        this.generateNumbersList(this.days, 0, daysInMonth);
      }
    }
    else {
      let daysLength = this.days.length
      if(this.isExactDate) {
        daysLength = daysLength - 1;
      }
      else {
        daysLength = daysLength - 2;
      }

      if (daysLength > daysInMonth)
      {
        let differenceDays = daysLength - daysInMonth;
        for(let i = 0; i < differenceDays; i++) {
          this.days.pop();
        }
      }
      else if (daysLength < daysInMonth)
      {
        this.generateNumbersList(this.days, daysLength + 1, daysInMonth);
      }

      if(parseInt(this.datepickerForm.get('day').value) > daysInMonth) {
        this.datepickerForm.get('day').setValue('');
      }
    }
  }

  generateMonths() {
    if(this.isExactDate) {
      this.generateNumbersList(this.months, 0, 11, true);
    }
    else {
      this.generateNumbersList(this.months, 0, 12, true);
    }
  }

  generateYears() {
    let currentYear = this.currenDate.getFullYear();

    for(let i = currentYear - 4; i >= currentYear - 100; i--) {
      this.years.push(i);
    }

    for(let i = 0; i < 4; i++) {
      this.years.push(currentYear - i);
    }
  }

  listenFormChanges() {
    this.formSubscription = this.datepickerForm.valueChanges.subscribe((datepickerFormValues) => {
      if (this.selectedYear != datepickerFormValues.year) {
        this.selectedYear = parseInt(datepickerFormValues.year);

          this.generateDaysAndSetDayValue();

      }

      if (this.selectedMonth != datepickerFormValues.month) {
        this.selectedMonth = parseInt(datepickerFormValues.month);
        this.generateDaysAndSetDayValue();
      }

      if (this.selectedDay != datepickerFormValues.day) {
        this.selectedDay = parseInt(datepickerFormValues.day);
      }

      this.setValue();
    });
  }

  setValue() {
    if(this.isExactDate) {
      if(this.datepickerForm.valid) {
        let date = new Date(
          this.datepickerForm.get('year').value,
          this.datepickerForm.get('month').value,
          this.datepickerForm.get('day').value
        );
        this._value = moment(date).utc(true).toDate();
      }
      else {
        this.setInvalid();
      }
    }
    else {
      let _year = this.datepickerForm.get('year').value;
      let _month: string = this.datepickerForm.get('month').value;
      let _day = this.datepickerForm.get('day').value;

      if(_month == '' || _month == '0' || _month == 'null') {
        _month = null;
        _day = null;
      }

      if(_day == '' || _day == 0 || _day == 'null') {
        _day = null;
      }

      if((_year != '' && _year != 'null') && (_month == null || _day == null)) {
        let _monthStr: string = _month == null ? '0' :  _month.toString();
        let _dayStr: string = _day == null ? '0' : _day;
        this._valueUnknown = _year + "-" + (_monthStr.length == 1 ? '0' + _monthStr : _monthStr) + "-" +  (_dayStr.length == 1 ? '0' + _dayStr : _dayStr);
        this._value = null;
      }
      else if(this.datepickerForm.valid) {
        let date = new Date(
          _year,
          Number(_month) - 1,
          Number(_day)
        );
        this._value = moment(date).utc(true).toDate();
        this._valueUnknown = null;
      }
      else {
        this.setInvalid();
      }
    }
    this.onChanges();
  }

  generateNumbersList(numbersList: ListItem<number>[], start: number, end: number, isMonth: boolean = false) {
    for(let i = start; i <= end; i++) {
      let n = isMonth && this.isExactDate ? i + 1 : i;
      let numberLabel = (n < 10 ? '0' + n : n).toString();
     if(!this.isExactDate && numberLabel == '00') {
      numbersList.push({ label: 'unknown', value: i });
     }
     else {
      numbersList.push({ label: numberLabel, value: i });
     }
    }
  }

  setInvalid() {
    this.datepickerForm.setErrors({ invalidDate: "invalid date"});
    this._value = null;
    this._valueUnknown = null;
  }

  onChanges() {
    if(!this._valueUnknown) {
      this.onChanged(this._value);
    }
    else {
      this.onChanged(this._valueUnknown);
    }

    this.onTouched();
  }

  ngOnDestroy() {
    if(this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
