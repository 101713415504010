import { Injectable } from '@angular/core';
import { HeaderService } from '../core/services/header.service';
import { PortalEnum } from '../shared/enums/portal.enum';
import { BrandPortal } from '../shared/models/brandPortal.model';
import { Brands } from '../shared/enums/brands.enum';

@Injectable()
export class LogoImmsResolve {
  constructor(private headerService: HeaderService) { }

  resolve() {
    let brand : Brands = JSON.parse(sessionStorage.getItem("brand"));
    let brandPortal: BrandPortal = { brand :  brand, portal : PortalEnum.ParentPortal};
    this.headerService.initLogo(brandPortal);
  }
}
