export interface QuestionOptions {
  id: string;
  order: number;
  title: string;
  value: any;
  valueText: string;
  isOther?: boolean;
  isMarked: boolean;
  markMessage?: string;
  flagType: number;
  isPublicHealthMessageVisible: boolean;
}
