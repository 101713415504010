import { Component, OnInit } from '@angular/core';
import { EnvironmentConfigsLoaderService } from "../services/environment-configs-loader.service";
import { FooterService } from './footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  adminWebUrl: string;
  showFooter: boolean;
  showGdpr: boolean = false;
  isNewTemplate: boolean = false;
  // privacyPolicy: string = "https://schoolscreener.com/privacy-policy/";
  // termsConditions: string = "https://schoolscreener.com/terms-and-conditions/";
  brandName: string;
  constructor(environmentConfigs: EnvironmentConfigsLoaderService, private footerService: FooterService) {
    this.adminWebUrl = location.origin + '/' + environmentConfigs.AdminWebUrl;
  }

  ngOnInit() {
    this.brandName = JSON.parse(sessionStorage.getItem("brand"));
    this.listenHideFooter();
    this.listenShowGdpr();
    this.changeTemplate();
  }

  listenHideFooter() {
    this.footerService.hideFooter.subscribe((show: boolean)=>{
      this.showFooter = show;
    });
  }

  listenShowGdpr() {
    this.footerService.showGdpr.subscribe((show: boolean)=>{
      this.showGdpr = show;
    });
  }

  changeTemplate() {
    this.footerService.changeTemplate.subscribe((isNewTemplate: boolean)=>{
      this.isNewTemplate = isNewTemplate;
    });
  }
}
