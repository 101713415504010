<div class="preview-container" [ngClass]="!isFromNotification ? 'border-page' : ''">
  <div *ngIf="!isFromNotification" class="title"><span >{{ title }}</span></div>
  <div [innerHTML]="previewHTML" class="preview-content"></div>
  <span *ngIf="sharedDocumentUploads?.length > 0" class="attachment">{{ 'Common_Attachments' | resourcekey}}</span>
  <div
    class="file-name uploadItem"
    *ngFor="let uploadItem of sharedDocumentUploads"
  >
    {{ uploadItem.fileName }} . {{ uploadItem.mimeType }}
    <button
      mat-icon-button
      (click)="
        downloadSharedDocument(
          uploadItem.sharedDocumentUploadId,
          uploadItem.fileName
        )
      "
      class="export-button"
    >
      <mat-icon>file_download</mat-icon>
    </button>
  </div>
</div>
<div class="delete-button-group" *ngIf="!isFromNotification">
  <button class="delete-button" type="button" mat-raised-button color="primary" (click)="deleteReportHistory(message, false)">{{ 'Common_Delete' | resourcekey}}</button>
  <button *ngIf="message.childMessage.messageSourceCount > 1" class="delete-button" type="button" mat-raised-button color="primary" (click)="deleteReportHistory(message, true)">{{ 'Common_DeleteAll' | resourcekey}} ({{message.childMessage.messageSourceCount}})</button>
</div>
