<div class="datepicker-form-container">
    <form [formGroup]="datepickerForm" *ngIf="datepickerForm" class="datepicker-form" [ngClass]="{'datepicker-form-disabled': disabled }">
        <div *ngIf="isRequiredVisible" class="datepicker-form-label" [ngClass]="{'red-color': datepickerForm.invalid && datepickerForm.touched, 'gray-color': datepickerForm.valid ||  datepickerForm.pristine }">{{ label }}
        <span *ngIf="isRedRequired" class="red-color">{{requiredLabel}} </span></div>

        <div class="date-inputs-with-icon" >
            <select matNativeControl formControlName="day">
                <option *ngFor="let day of days" [value]="day.value">{{ day.label }}</option>
            </select>

            <span class="date-divider">/</span>

            <select matNativeControl formControlName="month">
                <option *ngFor="let month of months" [value]="month.value">{{ month.label }}</option>
            </select>

            <span class="date-divider">/</span>

            <select matNativeControl formControlName="year">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
            </select>

            <mat-icon class="material-icons datepicker-icon">today</mat-icon>
        </div>
        <div class="red-color error-message" *ngIf="isRequiredVisible && datepickerForm.invalid && datepickerForm.touched">{{ "Common_Required" | resourcekey }}</div>
    </form>
</div>