export class FormManagerTableItem {
  id: string; 

  firstName: string;

  lastName: string; 

  dateOfBirth: string;

  gender: number;

  parentInitials: string;

  schoolId: string;

  linkedQuestionnaireCount?: number;

  submissionDate?: Date;

  isDifferentSchoolSubmissionExists: boolean = false;

  isDeleted: boolean = false;
}
