import { PhotoscreenerDevice } from 'src/app/features/screener/shared/enums';
import { TestAvailabilityEnum } from '../enums/test-availability.enum';
import { userAccessRightsModel } from './user-access-right.model';

export interface SessionUser {
  userId: string;
  access_token: string;
  tenantCode: string;
  userName: string;
  userRole: string;
  firstName: string;
  lastName: string;
  email: string;
  immunisationAvailability: TestAvailabilityEnum;
  healthNeedsAssessmentAvailability: TestAvailabilityEnum;
  covidTestManagerAvailability: TestAvailabilityEnum;
  growthTestAvailability: TestAvailabilityEnum;
  tenantDefaultCohort: string;
  userCulture: string;
  isNurse: boolean;
  tenantId: string;
  photoscreenerDevice: PhotoscreenerDevice;
  tenantDateFormatString: string;
  tenantIdEntryEnabled: boolean;
  tenantHealthIdVerificationEnabled: boolean;
  permissions: userAccessRightsModel;
  visionTestInteractiveMethodEnabled: boolean;
  visionTestKeelerMethodEnabled: boolean;
  visionTestManualMethodEnabled: boolean;
  tenantUnitOfMeasure: string;
  tenantTermStartingMonth: number;
  tenantChildMergeEnabled: boolean;
}
