import { CampaignTypeEnum } from '../../../../shared/enums/campaign-type.enum';

export class FormManagerFilter {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: number;
  campaignId: string;
  campaignType: CampaignTypeEnum;
  pageSize: number;
  pageCount: number;
  linkedQuestionnaireCountSortOrder: string;

  reset() {
    this.firstName = '';
    this.lastName = '';
    this.dateOfBirth = '';
    this.gender = 0;
    this.pageSize = 25;
    this.pageCount = 0;
    this.linkedQuestionnaireCountSortOrder = '';
  }
}
