export enum RolesEnum {
  User = "User",
  Screener = "Screener",
  Administrator = "Administrator",
  TenantAdministrator = "TenantAdministrator",
  Nurse = "Nurse",
  Clinician = "Clinician",
  ReadonlyReporting = "ReadonlyReporting",
  ReadonlyKPIUser = "ReadonlyKPIUser"
}
