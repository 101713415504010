export enum SpeechSoundTestStates {
  Absent = 1,
  ProfessionalCare = 2,
  NoConsent = 3,
  Pass = 4,
  Fail = 5,
  UnableToComplete = 7,
  ForcedReferral = 8,
  RepeateTestInQuiterEnvironment = 9,
  ReviewInFewWeeks = 10,
  SelectedForScreening = 20
}
