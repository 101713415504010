import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConsentForm, GPDetails } from 'src/app/shared/models';
import { ServicesModule } from '../services.module';
import { PortalSubmissionCard } from '../../../features/parent-portal/shared/models/portal-submission-card.model';
import { CampaignTypeEnum } from '../../../shared/enums/campaign-type.enum';
import { PortalSubmission } from '../../../features/parent-portal/consent-form-page/child-details-step/portal-submission';

@Injectable({
  providedIn: ServicesModule
})
export class HttpImmunisationService {

  constructor(private http: HttpClient) { }

  getCampaignByCode(code: string) {
    return this.http.get('api/campaign/code/' + code);
  }

  getCampaignByCodeWithSchool(code: string, schoolCode: string) {
    return this.http.get('api/campaign/code/' + code + '/schoolcode/' + schoolCode);
  }

  getCampaignSchoolsParentPortalLinks(code: string) {
    return this.http.get('api/campaign/parentPortalLinks/' + code);
  }

  getCampaignPortalSchoolLinks(code: string) {
    return this.http.get('api/campaign/campaignPortalSchoolLinks/' + code);
  }

  getCampaignPortalTypeByCode(code: string) {
    return this.http.get('api/campaign-portal/code/' + code + '/portal-type');
  }

  validateCampaignAndSchoolCode(campaignCode: string, schoolCode: string) {
    return this.http.get('api/validate/campaign/' + campaignCode + '/schoolcode/' + schoolCode);
  }

  getSubmissionById(submissionId: string, campaignType: CampaignTypeEnum) {
    return this.http.get('api/parent/getsubmission/' + submissionId + '/' + campaignType);
  }

  updateSubmission(form: PortalSubmission, campaignType: CampaignTypeEnum, gpDetails?: GPDetails) {
    return this.http.post('api/submission/update',
      {
        form: form,
        campaignType: campaignType,
        gpDetails: gpDetails
      }
    );
  }

  duplicateSubmission(submissionId: string, campaignType: CampaignTypeEnum) {
    return this.http.get('api/submission/duplicate/' + submissionId + '/' + campaignType);
  }

  getEthnies() {
    return this.http.get('api/children/getethnies');
  }

  getSubmissions(): Observable<PortalSubmissionCard[]> {
    return this.http.get<PortalSubmissionCard[]>('api/parent/getsubmissions');
  }

  getVaccinesByCampaignId(campaignId: string) {
    return this.http.get('api/vaccines/' + campaignId);
  }

  addConsentForm(consentForm: ConsentForm) {
    return this.http.post('api/consentform/add/', consentForm);
  }
}
