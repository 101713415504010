import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-input-datepicker',
  templateUrl: './formly-input-datepicker.component.html',
  styleUrls: ['./formly-input-datepicker.component.scss']
})
export class FormlyInputDatepickerComponent extends FieldType {

}
