import { Component, Input } from '@angular/core';
import { LoadingIndicator } from './loading-indicator.model';
import { LoadingStepsEnum } from './loading-steps.enum';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() loadingIndicator: LoadingIndicator;

  loadingStepsEnum = LoadingStepsEnum;

  constructor() { }
}
