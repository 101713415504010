import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HttpCoreService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root'
})
export class EthniesResolve implements Resolve<any> {
  
  constructor(private httpCoreService: HttpCoreService) {}
  
  resolve(route: ActivatedRouteSnapshot) {
    return this.httpCoreService.services.immunisation.getEthnies();
  }
}
