<div>
  <div class="row empty-line">
    <div *ngIf="isRegister" class="col-sm-12 col-md-12 align-center">
      <p class="hint-text">{{ 'RegisterSocialMediaAccount' | resourcekey }}</p>
    </div>
    <div *ngIf="!isRegister" class="col-sm-12 col-md-12 align-center">
      <p class="hint-text">{{ 'LoginSocialMediaAccount' | resourcekey }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="row align-center">
        <div class="facebook social-btn">
          <a (click)="signInWithFacebook()">
            <img [src]="'assets/svg-icons/facebook.svg'" alt="facebook" />
            {{ 'ContinueWithFacebook' | resourcekey }}
          </a>
        </div>
      </div>

      <div class="row align-center">
        <div class="microsoft social-btn">
          <a (click)="signInWithMicrosoft()">
            <img [src]="'assets/svg-icons/microsoft.svg'" alt="microsoft" />
            {{ 'ContinueWithMicrosoft' | resourcekey }}
          </a>
        </div>
      </div>

      <div class="row align-center">
        <div class="google social-btn" id="google-button" [ngStyle]="showRegister == true && {'margin-left': '-18px'}"></div>
      </div>

      <!-- <div class="row align-center">
    <div class="nhs social-btn">
      <a>
        <img [src]="'assets/svg-icons/nhs.svg'" alt="nhs"/> Continue with NHS
      </a>
    </div>
    </div> -->
    </div>
  </div>
</div>
