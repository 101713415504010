import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { Subscription, merge, fromEvent, timer } from 'rxjs';
import { bufferTime } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InactiveUserDialogComponent } from 'src/app/shared/components/inactive-user-dialog/inactive-user-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UserInactiveService {
  timeout: EventEmitter<void> = new EventEmitter<void>();

  private _idleTime = 1200;
  private _timeoutTime = 600;

  private _isInactiveUser: boolean;
  private _isCounterIdleStarted: boolean;
  private _isCounterTimeoutStarted: boolean;
  private _isDialog: boolean = false;

  private _dialogRef: MatDialogRef<InactiveUserDialogComponent>;

  private _watchSubscription: Subscription;
  private _idleSubscription: Subscription;
  private _timeoutSubscription: Subscription;

  constructor(
    private ngZone: NgZone,
    private dialog: MatDialog
  ) { }

  startWatching() {
    this._watchSubscription = this.getEvents().pipe(bufferTime(5000)).subscribe(
      eventArr => {
        let isEvent: boolean = eventArr.length > 0;

        if (!isEvent && !this._isCounterIdleStarted && !this._isCounterTimeoutStarted) {
          this._isInactiveUser = true;
          this.startCountIdleTime();
        }
        else if (isEvent && this._isInactiveUser) {
          this.stopIdleAndTimeoutCounters();

          this._isCounterIdleStarted = false;
          this._isCounterTimeoutStarted = false;
          this._isInactiveUser = false;
        }
      });
  }

  stopWatching() {
    this.stopIdleAndTimeoutCounters();
    this.closeDialog();

    this._isCounterIdleStarted = false;
    this._isCounterTimeoutStarted = false;
    this._isInactiveUser = false;

    if (this._watchSubscription) {
      this._watchSubscription.unsubscribe();
    }
  }

  private startCountIdleTime() {
    this._isCounterIdleStarted = true;

    this.ngZone.runOutsideAngular(() => {
      this._idleSubscription = timer(this._idleTime * 1000).subscribe(() => {

        this.ngZone.run(() => {
          if (!this._isDialog) {
            this._dialogRef = this.dialog.open(InactiveUserDialogComponent, {
              autoFocus: false,
              panelClass: 'warning-dialog'
            });

            this._isDialog = true;
          }

          this._dialogRef.afterClosed().subscribe(() => {
            this._isDialog = false;
          });

          this.startCountTimeout();
        });
      });
    });
  }

  private startCountTimeout() {
    this._isCounterTimeoutStarted = true;

    this.ngZone.runOutsideAngular(() => {
      this._timeoutSubscription = timer(this._timeoutTime * 1000).subscribe(() => {

        this.ngZone.run(() => {
          this.timeout.emit();
        });

      });
    });
  }

  private getEvents() {
    return merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keyup')
    );
  }

  private stopCountIdleTime() {
    if (this._idleSubscription) {
      this._idleSubscription.unsubscribe();
    }
  }

  private stopCountTimeout() {
    if (this._timeoutSubscription) {
      this._timeoutSubscription.unsubscribe();
    }
  }

  private stopIdleAndTimeoutCounters() {
    this.stopCountIdleTime();
    this.stopCountTimeout();
  }

  private closeDialog() {
    if (this._dialogRef) {
      this._dialogRef.close();
    }
  }
}
