import { OnInit, Component, forwardRef, HostListener, Input } from '@angular/core';
import { AddressResult } from '../../models/addressResult';
import { HttpCoreService } from '../../../core/services';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
  selector: "uk-postcode-lookup",
  templateUrl: "./uk-postcode-lookup.component.html",
  styleUrls: ["./uk-postcode-lookup.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UkPostcodeLookupComponent),
      multi: true
    }
  ]
})
export class UkPostcodeLookupComponent implements ControlValueAccessor {
   @HostListener('document:click', ['$event']) onDocumentClick(event) {
     this.showAddressList = false;     

  //   // if (!!this.postCodeCtrl.value && this.postCodeCtrl.value != this._value && !this.selectedAddress) {
  //   //   this.writeValue(this.mapAddressWithOnlyPostCode(this.postCodeCtrl.value));
  //   // }
   }

  addressResults: any = [];
  selectedAddress: AddressResult;

  onChanged: any = () => { };
  onTouched: any = () => { };

  private _value: AddressResult;

  @Input() disabled: boolean;

  postCodeCtrl: FormControl = new FormControl();
  postCodeCtrlSubscription: Subscription;
  showAddressList: boolean = false;

  constructor(private httpCoreService: HttpCoreService) {
  }

  // ngOnInit() {
  //   this.listenForPostCode();
  // }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value): void {
    this._value = value;
    this.onChanged(this._value);
    this.onTouched();

    this.showAddressList = false;
  }

  addressChosen(event: MouseEvent, value) {
    event.stopPropagation();
    this.writeValue(this.mapAddress(value));       
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  refreshAddresses(postcode: string) {
    this.httpCoreService.services.screener.getAddressResultsFromPostCode(postcode).subscribe((result: any) => {
      this.showAddressList = true;
      this.addressResults = result.addresses;
    });
  }

  // listenForPostCode() {
  //   this.postCodeCtrlSubscription = this.postCodeCtrl.valueChanges
  //     .pipe(debounceTime(1000))
  //     .subscribe((newPostCode: string) => {
  //       newPostCode = newPostCode.replace(" ", "");
  //       if (newPostCode.length > 4) {
  //         this.refreshAddresses(newPostCode);
  //       }
  //     });
  // }

  mapAddress(rawAddress) {    
    this.selectedAddress = {
      line1 : rawAddress.line_1 + ((rawAddress.line_2 == '') ? "" : " " + rawAddress.line_2),
      line2: rawAddress.line_3 + ((rawAddress.line_4 == '') ? "" : " " + rawAddress.line_4),      
      line3: '',
      line4: '',
      locality: rawAddress.locality,
      county: rawAddress.county,
      townCity: rawAddress.town_or_city,
      postcode: this.postCodeCtrl.value,
      country: rawAddress.country
    } as AddressResult;

    return this.selectedAddress;
  }

  // mapAddressWithOnlyPostCode(rawAddress) {
  //   this.selectedAddress = undefined;

  //   return {
  //     line1: '',
  //     line2: '',
  //     line3: '',
  //     line4: '',
  //     locality: '',
  //     county: '',
  //     townCity: '',
  //     postcode: rawAddress,
  //     country: ''
  //   } as AddressResult;
  // }

  openAddressList(event: MouseEvent) {
    event.stopPropagation();
    this.showAddressList = true;
  }

  findAddress() {
    let newPostCode = this.postCodeCtrl.value.replace(" ", "");
    if (newPostCode.length > 4) {
      this.refreshAddresses(newPostCode);
    }
  }

  ngOnDestroy() {
    if (this.postCodeCtrlSubscription) {
      this.postCodeCtrlSubscription.unsubscribe();
    }
  }
}
