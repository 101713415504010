import { TriageEnum } from '../enums/triage.enum';

export class Triage {
  id?: string;
  triageState: TriageEnum;
  triagedOn: Date;
  childId: string;
  consentFormId: string;
  schoolId: string;
  triagedById: string;
  notes: string;
  campaignId?: string;
  isPaperConsent: boolean;
  triagedVaccines?: string[];
  vaccineId?: string;
}
