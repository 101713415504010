import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { UrlSanitizer } from 'src/app/core/services/url-sanitizer.service';

@Component({
  selector: 'image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {
  @Input() isLocalFile: boolean; 
  @Input() image: any;
  imageSrc: any;

  constructor(private urlSanitizer: UrlSanitizer) { }

  ngOnInit() {
    if (this.isLocalFile) {
      this.previewImage();
    }
    else {
      this.previewExistingImage();
    }
  }

  previewImage() {
    let reader = new FileReader();

    reader.onload = (e: any) => {
      this.imageSrc = e.target.result;
    };

    reader.readAsDataURL(this.image);
  }

  previewExistingImage() {
    this.imageSrc = this.urlSanitizer.sanitizeUrl(this.image);
  }
}
