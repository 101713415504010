import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from './core/services/loading.service';
import { LoadingDialogComponent } from './shared/components/loading-dialog/loading-dialog.component';
import { LoadingIndicator } from './shared/components/loading/loading-indicator.model';
import { LoadingStepsEnum } from './shared/components/loading/loading-steps.enum';
import { HttpCoreService } from './core/services';
import { Brands } from './shared/enums/brands.enum';
import { DOCUMENT } from '@angular/common';
import { ResourceFilter } from './shared/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  loadingDialogRef: MatDialogRef<LoadingDialogComponent>;
  locale: string = "";
  domain: string = "";
  brand: string = "";
  tenantCode: string = "";
  isLoaded: boolean = false;
  constructor(@Inject(DOCUMENT) private document: any, private loadingService: LoadingService, private httpService: HttpCoreService,
    public dialog: MatDialog) {

    sessionStorage.removeItem("resources");
    this.isLoaded = false;  
    
    this.listenForLoading();
    var url = new URL(window.location.href.replace('#/', ''));
    this.locale = url.searchParams.get("locale");
    // let sessionLocale = JSON.parse(sessionStorage.getItem("locale"));

    // if (!this.locale && sessionLocale && sessionLocale != '') {
    //   this.locale = sessionLocale;
    // }

    if (this.locale && (this.locale.toLowerCase() == 'en-GB'.toLowerCase() || this.locale.toLowerCase() == 'fr-FR'.toLowerCase()
      || this.locale.toLowerCase() == 'en-US'.toLowerCase() || this.locale.toLowerCase() == 'es-ES'.toLowerCase()
      || this.locale.toLowerCase() == 'de-DE'.toLowerCase() || this.locale == '***')) {
      sessionStorage.setItem("locale", JSON.stringify(this.locale));
    }
    else {
      sessionStorage.setItem("locale", JSON.stringify(""));
      this.locale = '';
    }

    //this is for test environment
    // if(this.document.location.hostname.split(".")[2] == "org") {
    //   this.domain = "schoolscreenerforschools";
    // }
    // else {
    //   this.domain = "schoolscreener";
    // }

    //this is for production environment
    let domainStr: string = this.document.location.hostname.split(".")[1];
    if (domainStr != "workscreener" && domainStr != "cityscreener" && domainStr != "schoolscreenerforschools") {
      this.domain = "schoolscreener";
    }
    else {
      this.domain = domainStr;
    }


    this.setBrandName(this.domain);

    let brand: Brands = JSON.parse(sessionStorage.getItem("brand"));
    const favIcon = document.createElement('link');
   
    if (brand == Brands.SchoolScreenerForSchools) {
      favIcon.innerHTML = '<link id="favicon" type="image/x-icon" href="assets/schoolscreenerforschools/favicon.ico" rel="icon">';
    }
    else if (brand == Brands.WorkScreener) {
      favIcon.innerHTML = '<link id="favicon" type="image/x-icon" href="assets/workscreener/favicon.ico" rel="icon">';
    }
    else if (brand == Brands.CityScreener) {
      favIcon.innerHTML = '<link id="favicon" type="image/x-icon" href="assets/cityscreener/favicon.ico" rel="icon">';
    }
    else {
      favIcon.innerHTML = '<link id="favicon" type="image/x-icon" href="assets/schoolscreener/favicon.ico" rel="icon">';;
    }
    const head = document.head;
    while (favIcon.firstChild) {
      head.appendChild(favIcon.firstChild);
    }

    let filter: ResourceFilter = { culture: this.locale != '' && this.locale != null ? this.locale : 'en-GB', domainName: '', tenantCode: this.tenantCode ? this.tenantCode : '', branding: this.brand };
    this.httpService.services.resources.getTextResources(filter).subscribe((data: any) => {
      sessionStorage.setItem("resources", JSON.stringify(data));
      this.isLoaded = true;
    });
  }

  setBrandName(domainName: string) {
    if (domainName == 'schoolscreenerforschools') {
      this.brand = Brands.SchoolScreenerForSchools;
    }
    else if (domainName == 'workscreener') {
      this.brand = Brands.WorkScreener;
    }
    else if (domainName == 'cityscreener') {
      this.brand = Brands.CityScreener;
    }
    else {
      this.brand = Brands.SchoolScreener;
    }
    sessionStorage.setItem("brand", JSON.stringify(this.brand));
  }



  listenForLoading() {
    this.loadingService.loadingStep.subscribe((step: LoadingStepsEnum) => {
      if (step == LoadingStepsEnum.Loading) {
        let loading: LoadingIndicator = {
          step: LoadingStepsEnum.Loading
        };

        this.openLoadingDialog(loading);
      }
      else if (step == LoadingStepsEnum.Content) {
        this.closeLoadingDialog();
      }
    });
  }

  private openLoadingDialog(loadingIndicator: LoadingIndicator) {
    this.loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      width: "120px",
      height: "120px",
      data: loadingIndicator,
      autoFocus: false,
      panelClass: "base-dialog",
      disableClose: true
    });
  }

  private closeLoadingDialog() {
    if (this.loadingDialogRef) {
      this.loadingDialogRef.close();
    }
  }
}
