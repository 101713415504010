export enum PhotoscreenerTestStates {
  Absent = 1,
  ProfessionalCare = 2,
  NoConsent = 3,
  Pass = 4,
  Fail = 5,
  ForcedReferral = 6,
  UnableToComplete = 7,
  Inconclusive = 8,
  MeasurementResultObtained = 9,
  MeasurementNoResultObtained = 10,
  SelectedForScreening = 20
}
