import { Question } from './question.model';
import { HNAFlagDetails } from 'src/app/features/screener/shared/models/hna-flag-details.model';

export interface QuestionnaireSection {
  id: string;
  title: string;
  description: string;
  questions: Question[];
  hnaTestFlagsDetails?: HNAFlagDetails;
} 
