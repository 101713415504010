import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { ResourceFilter } from '../../../shared/models';

@Injectable({
  providedIn: ServicesModule
})
export class HttpResourcesService {

  constructor(private http: HttpClient) { }

  getTenantCulture() {
    return this.http.get('api/culturecode/');
  }

  getTextResources(resourceFilter: ResourceFilter) {
    let parameters = new HttpParams();

    parameters = parameters.append('Branding', resourceFilter.branding);
    parameters = parameters.append('Culture', resourceFilter.culture);
    parameters = parameters.append('DomainName', resourceFilter.domainName);
    parameters = parameters.append('TenantCode', resourceFilter.tenantCode);

    return this.http.get('api/textresources', {
      params: parameters,
    });
  }
}
