import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenderPipe } from './gender.pipe';
import { HyperlinkPipe } from './hyperlink.pipe';
import { ResourcekeyPipe } from './resourcekey.pipe';
import { StringFormatPipe } from './string-format.pipe';
import { SignedPositiveNumberPipe } from './signed-positive-number.pipe';
import { ValueNotAvailable } from './value-not-available.pipe';
import { PermissionPipe } from './permission.pipe';
import { VisionAcuityScaleTypePipe } from './vision-acuity-scale-type.pipe';
import { SnellenValuePipe } from './snellen-value.pipe';
import { EnvironmentSourcePipe } from './environment-source.pipe';
import { GroupByPipe } from './groupBy.pipe';

@NgModule({
  declarations: [
    ResourcekeyPipe,
    HyperlinkPipe,
    GenderPipe,
    StringFormatPipe,
    EnvironmentSourcePipe,
    SignedPositiveNumberPipe,
    ValueNotAvailable,
    PermissionPipe,
    VisionAcuityScaleTypePipe,
    SnellenValuePipe,
    GroupByPipe
  ],
  imports: [
    CommonModule,

  ],
  exports: [
    ResourcekeyPipe,
    HyperlinkPipe,
    GenderPipe,
    EnvironmentSourcePipe,
    StringFormatPipe,
    SignedPositiveNumberPipe,
    ValueNotAvailable,
    PermissionPipe,
    VisionAcuityScaleTypePipe,
    SnellenValuePipe,
    GroupByPipe
  ]
})
export class SharedPipesModule { }
