<div class="row">
  <div class="col-sm-12 col-md-8 schools-dropdown" [ngClass]="{ 'schools-wrapper': showAddressList }">
      <mat-form-field class="school-form-field">
        <mat-label>Please enter postcode</mat-label>
        <div class="input-field" (click)="openAddressList($event)">
            <input matInput type="text" [formControl]="postCodeCtrl" autocomplete="off">
            <mat-icon class="input-arrow">arrow_drop_down</mat-icon>
        </div>
        <mat-hint align="end">{{ "PostCode_LookUp_Label" | resourcekey }}</mat-hint>
      </mat-form-field>    
    <div class="schools-list" *ngIf="showAddressList">
        <div *ngFor="let address of addressResults" 
            (click)="addressChosen($event, address)" class="school-item">
              <ng-container *ngIf="address">
                {{ address.line_1 }} {{ address.line_2 }} {{ address.line_3 }} {{ address.line_4 }}
              </ng-container>
        </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-4 find-button">
    <button mat-stroked-button type="button" (click)="findAddress()" class="mat-focus-indicator mat-raised-button mat-button-base mat-primary">{{ "Find_Address_Label" | resourcekey }}</button>
  </div>
</div>