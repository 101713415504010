export interface HNATest {
    id: string;
    childFirstName: string;
    childLastName: string;
    childDateOfBirth?: Date;
    childGender?: number;
    sessionId?: string;
    submissionDate?: Date;
    childId?: string;
    status?: number;
    campaignId?: string;
    bookingSlotId?: string;
    yearGroup?: string;
    parentFirstName?: string;
    parentLastName?: string;
    parentEmail?: string;
    parentPhoneNumber?: string;
    schoolId?: string;
    tenantId?: string;
    isAnon: boolean;
    childClass?: string;
    ethnicity?: string;
    healthId?: string;
}
