import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "letter-preview",
  templateUrl: "./letter-preview.component.html",
  styleUrls: ["./letter-preview.component.scss"]
})
export class LetterPreviewComponent implements OnInit {
  @Input()
  set html(value: any) {
    this.previewHTML = this.domSanitizer.bypassSecurityTrustHtml(value);
  }

  get html() {
    return this.previewHTML;
  }

  previewHTML: SafeHtml;
  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit() {}
}
