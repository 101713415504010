import { VaccineTestStatesEnum } from '../enums/vaccine-test-states.enum';
import { VaccinationMethod } from '../enums/vaccination-method.enum';
import { TriageEnum } from '../enums';
import { ConsentStatesEnum } from 'src/app/shared/enums/consent-states';

export interface VaccineTestModel {
  id: string,
  vaccineId: string,
  vaccineDisplayName: string,
  state: VaccineTestStatesEnum,
  stateDisplayName: string,
  testDate: Date,
  canView: boolean,
  canAdminister: boolean,
  screenerFullName: string,
  batchNumber: string,
  expirityDate: Date,
  vaccinationLocation: string,
  siteOfInjection: string,
  vaccinationMethod: VaccinationMethod,
  notes: string,
  hasPaperConsent: boolean,
  canUndo: boolean;
  vaccineAdministrationId: string;
  groupCode?: string;
  consentState?: ConsentStatesEnum;
  triageState?: TriageEnum;
  consentDisplayName: string;
  triageDisplayName: string;
  testState: VaccineTestStatesEnum;
  vaccineToolTip: string;
  consentInfo: string;
  triageInfo: string;
  administerInfo: string;
}
