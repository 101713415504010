import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'signedPositiveNumber'
})
export class SignedPositiveNumberPipe implements PipeTransform {

  constructor() { }

  transform(value: number) {
    if(value && value > 0) {
     return "+" + value;
    }

    return value;
  }
}