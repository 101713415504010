import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AccessControlEnum } from '../enums/access-control.enum';
import { userAccessRightsModel } from '../models/user-access-right.model';
import { RolesEnum } from '../enums/roles.enum';

@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {
  constructor(private authenticationService: AuthenticationService) { }

  checkUserModuleAccess(testType: number, userAccessRightModel: userAccessRightsModel, access: AccessControlEnum) {
    var userModuleAccess = userAccessRightModel.userModuleAccesses.filter(u => u.testType == testType);
    if (userModuleAccess.length > 0 && userModuleAccess[0].access == access) {
      return false;
    }
    else {
      return true;
    }
  }

  transform(value: string, name: string): any {

    let userAccessRightModel = this.authenticationService.currentUserValue.permissions;
    if(this.authenticationService.currentUserValue.userRole == RolesEnum.Clinician) {
      return true;
    }

    if (userAccessRightModel) {
      let valueInt: number = Number(value);

      if (name == 'hidden') {
        if (valueInt || valueInt == 0) {
          return this.checkUserModuleAccess(valueInt, userAccessRightModel, AccessControlEnum.No_Access);
        }
        else {
          var userRight = userAccessRightModel.userAssignedRights.filter(u => u.accessRights.name == value);
          if (userRight.length > 0) {
            let isModuleAccess: boolean = !this.checkUserModuleAccess(userRight[0].accessRights.testType, userAccessRightModel, AccessControlEnum.No_Access);
            return !(isModuleAccess && userRight[0].access == AccessControlEnum.Access);
          }
        }
      }
      else {
        if (valueInt || valueInt == 0) {
          return !this.checkUserModuleAccess(valueInt, userAccessRightModel, AccessControlEnum.Read_Only_Access);
        }
        else {
          var userRight = userAccessRightModel.userAssignedRights.filter(u => u.accessRights.name == value);
          if (userRight.length > 0) {
            let isModuleAccess: boolean = this.checkUserModuleAccess(userRight[0].accessRights.testType, userAccessRightModel, AccessControlEnum.No_Access);
            return !(isModuleAccess && userRight[0].access == AccessControlEnum.Access);
          }
        }
      }
    }
    return false;
  }
}
