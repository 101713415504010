export enum GrowthTestStates {
  Absent = 1,
  ProfessionalCare = 2,
  NoConsent = 3,
  Underweight = 4,
  HealthyWeight = 5,
  Overweight = 6,
  VeryOverweight = 7,
  UnableToComplete = 8,
  ChildOptOut = 9,
  OptOutOtherReason = 10,
  ForcedReferral = 11,
  SelectedForScreening = 20
}
