import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { RolesEnum } from 'src/app/shared/enums/roles.enum';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) { }

  canActivate(routes: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    let roles = routes.data.roles as Array<string>;
    let readonlyUsersAllowedRoutes = ['/school-folder', '/user-profile'];

    if (currentUser) {
      if (roles && roles.some(r => r == currentUser.userRole)) {
        if ((currentUser.userRole === RolesEnum.ReadonlyReporting || currentUser.userRole === RolesEnum.ReadonlyKPIUser) && !readonlyUsersAllowedRoutes.includes(state.url))
          this.router.navigate(['/school-folder'], { relativeTo: this.route });
        else
          return true;
      }
      else {
        switch (currentUser.userRole) {
          case RolesEnum.User:
            this.router.navigate([`${currentUser.tenantCode}/forms`]);
            break;
          case RolesEnum.Clinician:
            this.router.navigate(['/clinician'], { relativeTo: this.route });
            break;
          case RolesEnum.Screener:
          case RolesEnum.Administrator:
          case RolesEnum.TenantAdministrator:
            this.router.navigate(['/children'], { relativeTo: this.route });
            break;
          default:
            return false;
        }

        return false;
      }
    } else if (roles) {
      if (roles.length == 1 && roles[0] == RolesEnum.User) {
        //this.navigationService.navigate('login');
      }
      else {
        this.router.navigate(['/login']);
      }
      return false;
    } else {
      return true;
    }
  }
}
