import { Pipe, PipeTransform } from '@angular/core';
import { ResourceService } from 'src/app/core/services';
import { EnvironmentSourceEnum } from '../enums/environment-source.enum';

@Pipe({
  name: 'environmentSourceDisplayName'
})
export class EnvironmentSourcePipe implements PipeTransform {

  constructor(
    private resourceService: ResourceService,
  ) { }

  transform(value: EnvironmentSourceEnum): string {
    if (value) {
      return this.environmentSource.find(x => x.value == value).label;
    }
    return "";
  }

  private environmentSource = [
    {
      value: EnvironmentSourceEnum.Web,
      label: this.resourceService.getResourceByKey("Common_Web") + ' -'
    },
    {
      value: EnvironmentSourceEnum.Desktop,
      label: this.resourceService.getResourceByKey("Common_Desktop") + ' -'
    }
  ];

}
