import { Injectable } from '@angular/core';
import { ChildrenTableFilter } from '../../features/screener/shared/models';
import { School } from '../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private readonly storagePrefix = "screener."
  private readonly selectedTabStorageKey: string = "screener.selectedTab"

  readonly hnaCampaignRelatedModule: string = "hnaCampaignRelatedModule";

  getHNACampaignRelatedModule() {
    return this.get(this.hnaCampaignRelatedModule);
  }

  getSelectedImmsCampaignId(): string {
    let filter = this.getGeneric(ChildrenTableFilter);

    return filter.campaignId;
  }

  getSelectedHNACampaignId(): string {
    let filter = this.getGeneric(ChildrenTableFilter);

    return filter.HNACampaignId;
  }

  getSelectedSchoolId(): string {
    let school = this.getGeneric(School);

    if (school) {
      return school.id;
    }

    return;
  }


  getSelectedClinicId(): string {
    let clinic = this.getGeneric(School, 'clinic');

    if (clinic) {
      return clinic.id;
    }

    return;
  }

  getSelectedTab(): any {
    return this.get(this.selectedTabStorageKey);
  }

  setSelectedTab(selectedTab: any): any {
    this.set(this.selectedTabStorageKey, selectedTab);
  }

  getGeneric<T>(type: new () => T, suffix: string = ''): T {
    let storageKey = this.getStorageKey(type) + suffix;

    return this.get(storageKey) as T;
  }

  setGeneric<T>(type: new () => T, obj: T, suffix: string = '') {
    let storageKey = this.getStorageKey(type) + suffix;

    this.set(storageKey, obj);
  }

  removeGeneric<T>(type: new () => T, suffix: string = '') {
    let storageKey = this.getStorageKey(type) + suffix;

    sessionStorage.removeItem(storageKey);
  }

  get(storageName: string) {
    return JSON.parse(sessionStorage.getItem(storageName));
  }

  set(storageName: string, value: any) {
    sessionStorage.setItem(storageName, JSON.stringify(value));
  }

  remove(storageName: string) {
    sessionStorage.removeItem(storageName);
  }

  private getStorageKey<T>(type: new () => T): string {
    return this.storagePrefix + type.name;
  }

}
