export enum VisionTestStates{
  Absent = 1,
  ProfessionalCare = 2,
  NoConsent = 3,
  Pass = 4,
  Fail = 5,
  FailPlus = 8,
  ForcedReferral = 6,
  UnableToComplete = 7,
  SelectedForScreening = 20
}
