export enum ColorTestStates{
  Absent = 1,
  ProfessionalCare = 2,
  NoConsent = 3,
  Pass = 4,
  Fail = 5,
  UnableToComplete = 6,
  NeedsRetest = 7,
  ForcedReferral = 8,
  SelectedForScreening = 20
}
