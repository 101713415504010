import { FormGroup } from '@angular/forms';
import { KeyValuePair } from '../models';

export function MatchFormFields(controlNames: KeyValuePair<string>[]) {
  return (formGroup: FormGroup) => {

    controlNames.forEach(c => {
      const control = formGroup.controls[c.key];
      const matchingControl = formGroup.controls[c.value];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    });
  }
}
