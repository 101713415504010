import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { HeaderService } from '../services/header.service';
import { PortalEnum } from 'src/app/shared/enums/portal.enum';
import { NavigationEnd, Router } from "@angular/router";
import { RolesEnum } from 'src/app/shared/enums/roles.enum';
import { HttpCoreService } from '../services/http/httpcore.service';
import { SharedDocumentSourcesWithTenantLogoModel } from 'src/app/features/screener/shared/models/shared-documentSource-with-tenantLogo.model';
import { formatDate } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { Brands } from 'src/app/shared/enums/brands.enum';
import { ResourceService } from '../services';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FooterService } from '../footer/footer.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
  loggedIn: boolean = false;
  userName: string;
  email: string;
  isUserCornerHidden: boolean = false;
  btnLoginText: string = 'SIGN IN';
  btnNotInLoginPage: boolean = false;
  logoPath: string;
  disableLogo: boolean = false;
  homepageLink: string;
  tenantCode: string;
  isNotificationVisible: boolean = false;
  messageList: SharedDocumentSourcesWithTenantLogoModel[] = [];
  unreadMessageCount: number;
  tenantLogo: string;
  defaultCulture: string = "en-GB";
  culture: string;
  totalCount: number = 0;
  isClassSessionPortal: boolean;
  isParentPortal: boolean;
  isPublicSelfPortal: boolean;
  @ViewChild(MatMenuTrigger) notificationMenuBtn: MatMenuTrigger;
  notificationEventsubscription: Subscription;
  brand: Brands = Brands.SchoolScreener;
  portal: PortalEnum;
  imagesPath: string;
  logoHeader: string;
  logoLogin: string;
  isClinician: boolean = false;
  isAdminButtonVisible: boolean;
  isAdminView: boolean = false;
  switchButtonText: string = this.resourceService.getResourceByKey("Switch_To_Admin");
  isNewTemplate: boolean = false;
  isMobile: boolean = false;
  selectedHeaderTabIndex: number;
  constructor(
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private footerService: FooterService,
    private router: Router,
    private httpCoreService: HttpCoreService,
    private resourceService: ResourceService
  ) {
    this.notificationEventsubscription = this.headerService.getNotificationEvent().subscribe(() => {
      this.getUnreadMessages();
    })

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (window.location.href.indexOf('/admin/') !== -1) {
          this.switchButtonText = this.resourceService.getResourceByKey("Switch_To_Portal")
        }
        else {
          this.switchButtonText = this.resourceService.getResourceByKey("Switch_To_Admin");
        }
      }
    });

  }

  
  ngOnInit() {

    if (window.screen.width < 640) { // 768px portrait
      this.isMobile = true;
    }

    if (window.location.href.indexOf('/inbox') !== -1) { 
      this.selectedHeaderTabIndex = 1;
    }
    else {
      this.selectedHeaderTabIndex = 0;
    }

    this.changeTemplate(); 

    this.brand = JSON.parse(sessionStorage.getItem("brand"));
    this.authenticationService.currentUser.subscribe(user => {
      this.isNotificationVisible = false;
      this.loggedIn = user !== null;
      if (this.loggedIn) {
        this.userName = user.userName;
        this.tenantCode = user.tenantCode;
        this.email = user.email;
        this.setLogoPath();
        this.culture = this.authenticationService.currentUserValue.userCulture;
        this.isClinician = this.authenticationService.currentUserValue.userRole == RolesEnum.Clinician;
        this.isAdminButtonVisible = (this.authenticationService.currentUserValue.userRole ==
          RolesEnum.TenantAdministrator || this.authenticationService.currentUserValue.userRole == RolesEnum.Administrator)
          && user.tenantCode.toLocaleLowerCase().startsWith('zz')
        if (!this.culture) {
          this.culture = this.defaultCulture;
        }
      }
      else {
        this.disableLogo = true;
        this.isAdminButtonVisible = false;
      }
    })

    // this.router.events.subscribe(val => {

    //   if (!this.tenantCode || this.tenantCode == '') {
    //     if (val instanceof RoutesRecognized) {
    //       let param = val.url.substring(1);

    //       this.tenantCode = param.substring(0, param.indexOf("/"));

    //       this.setLogoPath();
    //     }
    //   }
    // });
    if (!this.tenantCode || this.tenantCode == '') {
      var hash = window.location.hash.replace('#/', '');
      this.tenantCode = hash.substring(0, hash.indexOf("/"));
      this.setLogoPath();
    }

    this.headerService.changeText.subscribe(txt => {
      this.btnLoginText = txt;
    });

    this.headerService.changeButton.subscribe(v => {
      this.btnNotInLoginPage = v;
    });

    this.headerService.changeLogo.subscribe(brandPortal => {
      this.portal = brandPortal.portal;  
      this.isPublicSelfPortal = brandPortal.portal == PortalEnum.PublicSelfPortal;    
      this.isClassSessionPortal = brandPortal.portal == PortalEnum.ClassSessionPortal && !this.isClinician;
      this.isParentPortal = brandPortal.portal == PortalEnum.ParentPortal;
      if (brandPortal.portal == PortalEnum.ClassSessionPortal || brandPortal.portal == PortalEnum.ParentPortal || brandPortal.portal == PortalEnum.PublicSelfPortal) {
        this.disableLogo = true;
      }
      else {
        this.disableLogo = false;
      }

      if (brandPortal.portal == PortalEnum.ParentPortal) {
        if (this.loggedIn) {
          this.homepageLink = 'forms';
          this.getUnreadMessages();
        }
        else {
          this.isParentPortal = true;
          this.isClassSessionPortal = false;
        }

      }
      else {
        let isReadonlyReportingUser = this.authenticationService.currentUserValue?.userRole == RolesEnum.ReadonlyReporting || this.authenticationService.currentUserValue?.userRole == RolesEnum.ReadonlyKPIUser;

        this.homepageLink = isReadonlyReportingUser ? '/school-folder' : 'home';
      }

      // this.tenantCode = this.route.parent.snapshot.paramMap.get("tenantCode") ?? "";

      this.setLogoPath();
    });
  }

  setLogoPath() {

    this.imagesPath = 'assets/' + this.brand.toLowerCase() + "/";
    this.logoHeader = this.imagesPath + 'logo_header.png';
    this.logoPath = this.logoHeader;
  }

  getUnreadMessages() {
    this.httpCoreService.services.user.getUnreadParentMessages().subscribe((data: SharedDocumentSourcesWithTenantLogoModel[]) => {
      this.messageList = data;
      if (this.messageList && this.messageList.length > 0) {
        this.totalCount = this.messageList[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.messageList.forEach(message => {
        message.sharedDocumentSource.createdOnString = formatDate(message.sharedDocumentSource.createdOn, "yyyy-MM-dd", this.culture);
      })
      this.isNotificationVisible = true;
    });
  }

  clickMenuItem(message: SharedDocumentSourcesWithTenantLogoModel) {
    let data = {
      sharedDocumentSourceId: message.sharedDocumentSource.id,
      userRelationShipId: message.userRelationShipId,
      title: message.sharedDocumentSource.sharedDocument.title,
      logo: message.tenantLogo,
      content: message.sharedDocumentSource.sharedDocument.content,
      tenantName: message.tenantName,
      createdOn: message.sharedDocumentSource.createdOn,
    };
    sessionStorage.setItem("messagePreviewData", JSON.stringify(data));
    this.router.navigate(['forms/letters/message/preview/'], { queryParams: { sourceId: data.sharedDocumentSourceId } });

  }

  logout() {
    this.selectedHeaderTabIndex = 0;
    this.footerService.changeTemplate.emit(false);
    this.headerService.changeTemplate.emit(false);
    this.authenticationService.logout();
  }

  userProfile() {
    if (this.isClinician) {
      this.router.navigate(['clinician-user-profile']);
    } else if (this.portal == PortalEnum.ParentPortal) {
      this.router.navigate(['forms/user-profile']);
    } else {
      this.router.navigate(['user-profile']);
    }
  }

  getVisibility() {
    this.headerService.toggleVisibility();
  }

  changeUserCornerVisibility() {
    this.isUserCornerHidden = !this.isUserCornerHidden;
    if (this.isNotificationVisible && this.totalCount > 0) {
      if (!this.isUserCornerHidden) {
        this.notificationMenuBtn.closeMenu();
      } else {
        this.notificationMenuBtn.openMenu();
      }
    }
  }

  onSwitchButton() {
    if (this.isAdminView) {
      this.isAdminView = false;
      this.switchButtonText = this.resourceService.getResourceByKey("Switch_To_Admin")
      this.router.navigate(['children']);
    }
    else {
      this.isAdminView = true;
      this.switchButtonText = this.resourceService.getResourceByKey("Switch_To_Portal");
      this.router.navigate(['admin/schools']);
    }
  }

  changeTemplate() {
    this.headerService.changeTemplate.subscribe((isNewTemplate: boolean)=>{
      this.isNewTemplate = isNewTemplate;
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedHeaderTabIndex = tabChangeEvent.index;
    if(tabChangeEvent.index == 0) {
      this.goToForms();
    }
    else {
      this.goToInbox();
    }
}

  goToForms() {
    this.router.navigate(['forms']);
  }

  goToInbox() {
    this.router.navigate(['inbox']);
  }
}
