import { FormlyFieldConfig } from '@ngx-formly/core';

export function RequiredValidatorMessage(err, field: FormlyFieldConfig): string {
  return `${field.templateOptions.label} is required`;
}

export function MaxLengthValidatorMessage(err, field: FormlyFieldConfig): string {
  return `${field.templateOptions.label} must have maximum ${field.templateOptions.maxLength} characters`;
}


