import { Pipe, PipeTransform } from '@angular/core';
import { ResourceService } from 'src/app/core/services';
import { GenderEnum } from '../enums/gender.enum';

@Pipe({
  name: 'genderDisplayName'
})
export class GenderPipe implements PipeTransform {

  constructor(
    private resourceService: ResourceService,
  ) { }

  transform(value: GenderEnum): string {
    if (value) {
      return this.childGender.find(x => x.value == value).label;
    }
    return "";
  }

  private childGender = [
    {
      value: GenderEnum.Male,
      label: this.resourceService.getResourceByKey("Common_Male")
    },
    {
      value: GenderEnum.Female,
      label: this.resourceService.getResourceByKey("Common_Female")
    },
    {
      value: GenderEnum.Not_Recorded,
      label: this.resourceService.getResourceByKey("Common_Not_Recorded")
    }
  ];

}
