export class School {
    id: string;
    name?: string;
    organizationId?: string;
    addressLine1?: string;
    addressLine2?: string;
    postcode?: string;
    city?: string;
    country?: string;
    county?: string;
    emails?: string;
    phoneNumber?: string;
    code?: string;
}
