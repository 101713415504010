import { Injectable } from '@angular/core';
import { HttpImmunisationService } from './http-immunisation.service';
import { ServicesModule } from '../services.module';
import { HttpScreenerService } from './http-screener.service';
import { HttpResourcesService } from './http-resources.service';
import { HttpHNAService } from './http-hna.service';
import { HttpQuestionnaireService } from './http-questionnaire.service';
import { HttpLetterService } from './http-letter.service';
import { HttpTestsService } from './http-tests.service';
import { HttpUserService } from './http-user.service';
import { HttpCovidTestService } from './http-covid-test.service';

@Injectable({
  providedIn: ServicesModule
})
export class HttpCoreService {

  constructor(
    private httpImmunisationService: HttpImmunisationService,
    private httpScreenerService: HttpScreenerService,
    private httpResourcesService: HttpResourcesService,
    private httpHNAService: HttpHNAService,
    private httpQuestionnaire: HttpQuestionnaireService,
    private httpLetterService: HttpLetterService,
    private httpTestsService: HttpTestsService,
    private httpParentService: HttpUserService,
    private httpCovidTestService: HttpCovidTestService
  ) {}

  get services() {
    return {
      immunisation: this.httpImmunisationService,
      screener: this.httpScreenerService,
      resources: this.httpResourcesService,
      hna: this.httpHNAService,
      questionnaire: this.httpQuestionnaire,
      letterService: this.httpLetterService,
      tests: this.httpTestsService,
      user: this.httpParentService,
      covid: this.httpCovidTestService
    }
  }
}

