
export enum VaccineTestStatesEnum {
  Pending = 0,
  Absent = 1,
  Postponed = 2,
  Administered = 3,
  Removed = 4,
  Denied = 6,
  Refused = 14
}
