export class ListResults<T> {
    constructor() {
        this.init();
    }

    list: T[];
    total: number;

    public clear() {
        this.init();
    }

    public get length(): number {
        return this.list.length;
    }

    private init() {
        this.list = [];
        this.total = 0;
    }
}
