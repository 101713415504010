import { Campaign } from './campaign.model';
import { ConsentStatesEnum } from '../enums/consent-states';
import { GenderEnum } from '../enums/gender.enum';
import { GPDetails } from './gpDetails.model';
import { ScoreResultsEnum } from '../enums/score-results.enum';
import { CampaignPortalTypeEnum } from '../enums/campaing-portal-type.enum';

export interface ConsentForm {
  id: string;
  childId: string;
  childFirstName: string;
  childLastName: string;
  childDateOfBirth: Date;
  childGender: GenderEnum;
  childClass: string;
  childTown: string;
  childCounty: string;
  childPostcode: string;
  parentPhoneNumber: string;
  healthId: string;
  ethnicity: string;
  ethnicityDisplayName: string;
  homeAddress: string;
  homeAddress2: string;
  consentState: ConsentStatesEnum;
  campaign: Campaign;
  schoolId: string;
  schoolName: string;
  gpId?: string;
  gpDetails?: GPDetails;
  scoreResult?: ScoreResultsEnum;
  isLocked: boolean;
  isDeleted: boolean;
  tenantId: string;
  campaignId: string;
  bookingSlotId?: string;
  yearGroup?: string;
  parentFirstName?: string;
  parentLastName?: string;
  parentEmail?: string;
  submissionPortalType?: CampaignPortalTypeEnum;
}
