import { Component, OnInit, NgZone, AfterViewInit, Input } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Output, EventEmitter } from '@angular/core';
import { ActivityMethodEnum } from 'src/app/shared/enums/activity-method.enum';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { accounts } from 'google-one-tap';
import { environment } from 'src/environments/environment';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';

declare var google: any;

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {
  @Output() loggedUser = new EventEmitter<any>();
  @Input() showRegister: boolean;
  @Input() isRegister: boolean;
  decodedToken: any | null = null;
  user: SocialUser;
  loggedIn: boolean;
  auth2: any;
  constructor(
    private authService: SocialAuthService,
    private _snackBarService: SnackBarService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });

    window.google.accounts.id.initialize({
      client_id: "390179283289-g3b3q2pv5lakn030o505tnre7nuu07ri.apps.googleusercontent.com",
      callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: false

    });
    google.accounts.id.renderButton(

      document.getElementById("google-button"),
      { theme: "outline", size: "large", width: "210px" }
    );

    google.accounts.id.prompt((notification: PromptMomentNotification) => {

    });


  }

  async handleCredentialResponse(response: CredentialResponse) {

    try {
      this.ngZone.run(() => {
        this.decodedToken = JSON.parse(atob(response?.credential.split('.')[1]));
        this.loggedUser.emit({ user: this.decodedToken, activityMethod: ActivityMethodEnum.Gmail });
      });
    } catch (e) {
      this._snackBarService.openAlertSnackBar('The Google Login failed. Please make sure that 3rd party cookies have not been blocked.')
    }
  }


  signInWithFacebook(): void {
    const signinOptions = {
      scope: 'email'
    };

    this.signIn(FacebookLoginProvider.PROVIDER_ID, ActivityMethodEnum.Facebook, signinOptions);
  }

  signInWithMicrosoft(): void {
    this.signIn(MicrosoftLoginProvider.PROVIDER_ID, ActivityMethodEnum.Microsoft);
  }

  signOut(): void {
    this.authService.signOut();
  }
  signIn(providerId: string, activityMethodEnum: ActivityMethodEnum, signInOptions?: any) {
    this.authService.signIn(providerId, signInOptions).then((data) => {
      this.loggedUser.emit({ user: data, activityMethod: activityMethodEnum });
    }).catch(data => {
      if (providerId == GoogleLoginProvider.PROVIDER_ID) {
        this._snackBarService.openAlertSnackBar('The Google Login failed. Please make sure that 3rd party cookies have not been blocked.')
      }
    });
  }

}
