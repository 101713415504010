import { ConsentFormUpload } from '.';
import { ConsentStatesEnum } from 'src/app/shared/enums/consent-states';
import { ScoreResultsEnum } from 'src/app/shared/enums/score-results.enum';

export class PaperConsent {
  id: string;
  campaignId: string;
  childDateOfBirth: Date;
  childFirstName: string;
  childGender: string;
  childId: string;
  childLastName: string;
  consentFormUploads: ConsentFormUpload[];
  consentState: ConsentStatesEnum;
  scoreResult: ScoreResultsEnum;
  isLocked: boolean;
}
