import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class EnvironmentConfigsLoaderService {
  private httpClient: HttpClient;
  private configs: EnvironmentConfigs;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  get ScreenerWebUrl() {
    return this.configs.PublicWebSiteRoot;
  }

  get AdminWebUrl() {
    return this.configs.AdminWebSiteRoot;
  }

  public async loadConfigs(): Promise<any> {
    return this.httpClient
      .get("environments/env-config.json")
      .pipe((configs) => configs)
      .toPromise()
      .then((configs) => {
        this.configs = configs as EnvironmentConfigs;
      });
  }
}

export interface EnvironmentConfigs {
  PublicWebSiteRoot: string;
  AdminWebSiteRoot: string;
}
