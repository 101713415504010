import { DatePipe, formatDate, registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { GenderEnum } from 'src/app/shared/enums/gender.enum';
import { Child } from 'src/app/shared/models';
import { AuthenticationService, ResourceService } from '.';
import { FileService } from './file.service';
import localeFr from '@angular/common/locales/fr';

@Injectable({
  providedIn: 'root'
})
export class ExportPdfService {
  defaultCulture: string = "en-GB";
  frenchCulture: string = "fr-FR";
  culture: string;

  constructor(private fileService: FileService,
    private resourceService: ResourceService,
    private authenticationService: AuthenticationService) { 
      let userCulture = this.authenticationService.currentUserValue.userCulture;

      // TODO: add other cultures, like US and DE
      switch(userCulture) {
        case this.frenchCulture:
          this.culture = userCulture;
          registerLocaleData(localeFr);
          break;
        case this.defaultCulture:
        default:
          this.culture = this.defaultCulture;
      } 
    }

  downloadFile(data: any, filename: string = 'data') {
    let pdfByteArray = this.fileService.convertDataToBinary(data);
    let blob = new Blob([pdfByteArray], { type: 'application/pdf' });
    let url = URL.createObjectURL(blob);

    this.fileService.downloadFile(filename, "pdf", blob);
  }

  getPDFNameByChildAndTemplate(child: Child, templateName: string) {
    let pdfName = templateName;
    let gender: string  = child.gender == GenderEnum.Female ? this.resourceService.getResourceByKey('Common_Female') : (child.gender == GenderEnum.Male ? this.resourceService.getResourceByKey('Common_Male') : this.resourceService.getResourceByKey('Common_Not_Recorded'));

    if(child.nhs) {
      pdfName += '_' + child.nhs;
    }

    pdfName = pdfName += '_' + child.firstName + '_' + child.lastName + '_' + formatDate(child.dateOfBirth, "dd-MM-yyyy", this.culture) + '_' + gender + '_' + this.getDateForPDFName(); 
    
    return this.removeReservedCharacters(pdfName);
  }

  getBulkPDFName(templateName: string = null) {
    let pdfName = '';

    if(!templateName) {
      pdfName = 'MultipleFilesExport'; 
    }
    else {
      pdfName = templateName;
    }

    pdfName = pdfName + '_' + this.getDateForPDFName();

    return this.removeReservedCharacters(pdfName);
  }

  getDateForPDFName() {
    return formatDate(new Date(), "yyyy-MM-dd HH_mm_ss", this.culture);
  }

  removeReservedCharacters(templateName: string) {
    return templateName.replace(new RegExp(/[<>:"/\\|?*]/, 'g'), '');
  }
}
