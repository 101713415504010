import { FormGroup } from "@angular/forms";
import * as moment from "moment";

export function validateDateRange(dateStartControl, dateEndControl) {
    return (formGroup: FormGroup) => {
 
      const dateStart: Date = moment(formGroup.get(dateStartControl).value).utc(true).toDate();
      const dateEnd: Date = moment(formGroup.get(dateEndControl).value).utc(true).toDate();
 
      if (dateStart > dateEnd) {  
        formGroup.get(dateEndControl).setErrors({ 'dateRangeInvalid': true }); 
      }
      else 
      {
        formGroup.get(dateEndControl).setErrors(null);
      }
    }
  }
