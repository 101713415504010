import { QuestionResultTypeEnum } from '../enums/question-result-type.enum';
import { QuestionOptions } from './question-options.model';
import { QuestionResultFlagTypeEnum } from '../enums/question-result-flag-type.enum';
import { ResolvedQuestionResult } from './resolved-question-result.model';

export interface QuestionResult {
  id?: string;
  questionId: string;
  sourceId: string;
  resultType: QuestionResultTypeEnum;
  textAnswer?: string;
  dateAnswer?: Date;
  comment?: string;
  questionOptions?: QuestionOptions[];
  questionOptionIds?: string[];
  flagType?: QuestionResultFlagTypeEnum;
  resolvedQuestionResults?: ResolvedQuestionResult[];
  isMarked?: boolean;
}
