import { Injectable } from '@angular/core';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class ExportCsvService {

  constructor(private fileService: FileService) { }

  downloadFile(headerList: string[], data: any, filename: string = 'data') {
    let csvData: string 
    
    if (headerList) {
      csvData = this.ConvertToCSV(headerList, data);
    }
    else {
      csvData = this.ConvertToCSVWithoutHeader(data);
    }
    
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);

    this.fileService.downloadFile(filename, "csv", blob);
  }

  ConvertToCSV(headerList, data) {
    let header = '';
    let str = '';

    for (let index in headerList) {
      header += headerList[index] + ',';
    }

    str += header + '\r\n';

    if (data && data.length > 0) {
      let columns: string[] = Object.keys(data[0]);

      for (let i = 0; i < data.length; i++) {
        let line = '';

        for (let index in columns) {
          let column = columns[index];

          line += data[i][column].replace (/,/g, "").replace(/(\r\n|\n|\r)/gm, "") + ',';
        }

        str += line + '\r\n';
      }
    }

    return str;
  }

  ConvertToCSVWithoutHeader(data) {
    let str = '';

    if (data && data.length > 0) {
      let columns: string[] = Object.keys(data[0]);

      for (let i = 0; i < data.length; i++) {
        let line = '';

        for (let index in columns) {
          let column = columns[index];

          line += data[i][column].replace (/,/g, "").replace(/(\r\n|\n|\r)/gm, "") + ',';
        }

        str += line + '\r\n';
      }
    }

    return str;
  }
}
