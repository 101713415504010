import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BrandPortal } from 'src/app/shared/models/brandPortal.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  isVisible = true;

  change: EventEmitter<boolean> = new EventEmitter();

  changeText: EventEmitter<string> = new EventEmitter();

  changeButton: EventEmitter<boolean> = new EventEmitter();

  changeLogo: EventEmitter<BrandPortal> = new EventEmitter();

  changeTemplate: EventEmitter<boolean> = new EventEmitter();

  private notificationObject = new Subject<any>();

  toggleVisibility() {
    this.isVisible = !this.isVisible;
    this.change.emit(this.isVisible);
  }

  toggleText(hasSchoolId: boolean) {
    if (hasSchoolId) {
      this.changeText.emit('LOG IN');
    }
    else {
      this.changeText.emit('REGISTER');
    }
  }

  toggleButton(visible: boolean) {
    this.changeButton.emit(visible);
  }

  initLogo(brandPortal : BrandPortal) {
    this.changeLogo.emit(brandPortal);
  }

  sendNotificationEvent() {
    this.notificationObject.next();
  }
  getNotificationEvent(): Observable<any> {
    return this.notificationObject.asObservable();
  }
}
