export interface CovidTabDetailsModel {
    notes: string;
    testIdBarcode: string;
    furtherTestDate?: Date;
    testType: string;
    showingSymptoms: string;
    dateSymptomsStarted: Date;
    currentlyInWork: string;
    dailyContactTesting: string;
    vaccinationDate: string;
    doseInformation: string;
    photos: string[];
}