<div [ngSwitch]="loadingIndicator.step" class="loading-container">
    <ng-content *ngSwitchCase="loadingStepsEnum.Content"></ng-content>

    <div *ngSwitchCase="loadingStepsEnum.Loading" class="step">
        <mat-spinner color="primary" [diameter]="60"></mat-spinner>
    </div>

    <div *ngSwitchCase="loadingStepsEnum.Done" class="step done-step">
        <mat-icon class="done-icon">done</mat-icon>
        <p class="message" *ngIf="loadingIndicator.doneMessage">{{ loadingIndicator.doneMessage }}</p>
    </div>
</div>
