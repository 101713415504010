import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-uk-postcode-lookup',
  templateUrl: './formly-uk-postcode-lookup.component.html',
  styleUrls: ['./formly-uk-postcode-lookup.component.scss']
})
export class FormlyUkPostcodeLookupComponent extends FieldType {

}
