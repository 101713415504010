import { Child } from "./child";

export class TestListItem {
  id: string;
  date: Date;
  state: number;
  stateDisplayName: string;
  screener: string;
  testDetails: any;
  canDelete: boolean;
  submissionId?: string;
  child: Child;
  systemOneStatus: string;
  systemOneSyncInfo: Date;
  isDeleted: boolean;
}
