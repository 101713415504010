import { QueueTypeEnum } from "../enums/queue-type.enum";
import { ChildMessage } from "./child-message.model";

export class Letter {
  id: string;
  childId: string;
  pdfContentId: string;
  htmlContent: string;
  screenerInitials: string;
  createdOn: Date;
  letterType: string;
  firstName: string;
  lastName: string;
  class: string;
  school: string;
  isShared: boolean;
  queueType: QueueTypeEnum;
  yearGroup: string;
  isMessage: boolean;
  childMessage: ChildMessage;
  isPrivate: boolean;
}
