import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { ResourceService } from '.';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {

    constructor(
        private _snackBar: MatSnackBar,
        private resourceService: ResourceService
    ) { }

    dismiss() {
        this._snackBar.dismiss();
    }

    openAlertSnackBar(message): Observable<MatSnackBarDismiss> {
        let snakBarRef = this._snackBar.open(message, this.resourceService.getResourceByKey('Common_Close'), {
            panelClass: ['snackbar-alert'],
            duration: 30000
        });

        return snakBarRef.afterDismissed();
    }

    openSuccessSnackBar(message, duration = 3000): Observable<MatSnackBarDismiss> {
        let snakBarRef = this._snackBar.open(message, '', {
            panelClass: ['snackbar-success'],
            duration: duration,

        });

        return snakBarRef.afterDismissed();
    }
}
