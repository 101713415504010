import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { EnvironmentConfigsLoaderService } from 'src/app/core/services/environment-configs-loader.service';
import { LoginPortalType } from '../../enums/login-portal-type.enum';
import { SocialUser } from 'angularx-social-login';
import { ActivityMethodEnum } from '../../enums/activity-method.enum';
import { FooterService } from 'src/app/core/footer/footer.service';
import { LoginUser } from '../../models/login-user.model';
import { HttpCoreService } from 'src/app/core/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() showRegister: boolean = false;

  @Input() portalType: LoginPortalType;

  @Input() tenantCode: string;

  @Input() isCampaignLandingPage: boolean =false;

  @ViewChild("email") emailField;
  @ViewChild("loginContainer") loginFormField;
  isFromLandingPage: boolean = false;
  campaignCode: string;
  schoolCode: string;
  landingPageHeader: string = "Sorry, this page isn't available";
  landingPageText1: string = "The landing page you followed is closed for registration or has been removed. You can contact the organisation who provided the link for more information.";
  landingPageText2: string = "If you have previously registered to SchoolScreener or a WorkScreener account, please login by typing your username and password below.";
  isParentMFAEnabled: boolean = false;
  
  loginForm = this.formBuilder.group({
    email: ['',
      [
        Validators.required,
        Validators.email
      ]
    ],
    password: ['',
      [
        Validators.required,
        Validators.minLength(6)
      ]
    ]
  });

  get formControls() { return this.loginForm.controls; }

  constructor(
    private authenticationService: AuthenticationService,
    private httpCoreService: HttpCoreService,
    private environmentConfigsLoaderService: EnvironmentConfigsLoaderService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private footerService: FooterService
  ) { }

  ngOnInit() {
    this.footerService.hideFooter.emit(true);
    this.footerService.showGdpr.emit(true);
    this.campaignCode = this.route.parent.snapshot.paramMap.get("campaigncode");
    this.schoolCode = this.route.snapshot.paramMap.get("schoolcode");

    this.httpCoreService.services.user.getIsParentMFAEnabled().subscribe((data: boolean) => {
      this.isParentMFAEnabled = data;
    });

    if(this.route.queryParamMap) {
    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        if(params) {
          var param = params.get("warning");
          if(param && param == "refpagenotavailable") {
            this.isFromLandingPage = true;
          }
      }} );
    }
  }

  login() {
    if (this.loginForm.valid) {
      const user : LoginUser = {
        email: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
        tenantCode: this.tenantCode,
        campaignCode: this.campaignCode,
        schoolCode: this.schoolCode,
        portalType: this.portalType,
        loginMethod: ActivityMethodEnum.Username_And_Password,
      }
      this.authenticationService.login(user, this.router.url);
        this.footerService.hideFooter.emit(false);
        this.footerService.showGdpr.emit(false);
    }
  }

  loginWithSocialAccount(socialData: any) {

    const user : LoginUser = {
      email: socialData.user.email,
      password:  "",
      tenantCode: this.tenantCode,
      campaignCode: this.campaignCode,
      schoolCode: this.schoolCode,
      portalType: this.portalType,
      loginMethod: socialData.activityMethod,
    }

      this.authenticationService.login(user, this.router.url);
        this.footerService.hideFooter.emit(false);

  }

  forgotPassword(){
    // let brand = JSON.parse(sessionStorage.getItem("brand"));
    // let locale = JSON.parse(sessionStorage.getItem("locale"));
    // let adminUrl = `${window.location.origin}/${this.environmentConfigsLoaderService.AdminWebUrl}/Account/ForgotPassword?brand=${brand}&locale=${locale}`;
    // window.location.href = adminUrl;
    this.router.navigate(["/forgot-password"], { queryParams: { backUrl: this.router.url } });
  }

  focusOnLoginForm() {
    this.loginFormField.nativeElement.scrollIntoView();
    this.emailField.nativeElement.focus();
  }

  registerClinician() {
    this.router.navigate(['/' + this.tenantCode + '/Account/Register/Clinician/']);
  }
}
