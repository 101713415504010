import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hyperlink' })
export class HyperlinkPipe implements PipeTransform {

  transform(value: string, cssClass?: string): string {
    if (value) {
      return this.parseContent(value, cssClass);
    }

    return;
  }

  parseContent(content: string, cssClass?: string): string {
    let links: string[] = this.findLinks(content);

    if (links) {
      for (let i = 0; i < links.length; i++) {
        content = content.replace(links[i], this.addHtmlLink(links[i], cssClass));
      }
    }

    return content;
  }

  private findLinks(content: string): string[] {
    return content.match(/(((http|https):\/\/)|(w{3}\.))(\w+\.)+(\/|\w+|#|\?|=|\+|%|_)+/gi);
  }

  private addHtmlLink(link: string, cssClass?: string) {
    let cssLinkClass = 'hyperlink-pipe';

    if (cssClass) {
      cssLinkClass += ' ' + cssClass;
    }

    if (!this.hasHttpInLink(link)) {
      link = 'http://' + link;
    }

    return '<a href="' + link + '"' + 'class="' +  cssLinkClass + '" target="_blank">' + link + '</a>';
  }

  hasHttpInLink(link: string): boolean {
    return link.indexOf('http') >= 0;
  }
}
