export class ChildrenTableFilter {
  pageNumber: string;
  pageSize: string;
  cohort: string;
  campaignId?: string;
  HNACampaignId?: string;
  yearGroup: string;
  knownAs: string;
  class: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: number;
  healthId: string;
  additionalHealthId: string;
  email: string;
  notes: string;
  showOnlyDeletedChildren?: boolean;
  showOnlyIdConflictedNotFoundChildren?: boolean;
  showOnlyImmsConflictedChildren?: boolean;
  showOnlySyncProfileChildren?: boolean;

  covidTestDateStart: string;
  covidTestDateEnd: string;
  covidTestIdBarcode: string;
  covidNeedFurtherTestDateStart: string;
  covidNeedFurtherTestDateEnd: string;

  testDateStart: string;
  testDateEnd: string;

  sortBy: string[];

  testFilters: {};

  printedLetters: string[];
  notPrintedLetters: string[];

  hasAdvancedFilter: boolean;

  vaccineIds: string[];
  immsQueryBeginDate: string;
  consentTriageStates: number[];
  immsQueryEndDate: string;
  activities: number[];

  private defaultCohort: string;

  constructor(cohort: string = '') {
    this.defaultCohort = cohort;
  }

  reset() {
    this.pageNumber = '1';
    this.pageSize = '25';
    this.cohort = this.defaultCohort;
    this.yearGroup = '';
    this.class = '';
    this.firstName = '';
    this.lastName = '';
    this.dateOfBirth = '';
    this.gender = 0;
    this.testFilters = {};
    this.knownAs = '';
    this.notes = '';

    this.covidTestDateStart = '';
    this.covidTestDateEnd = '';
    this.covidTestIdBarcode = '';
    this.covidNeedFurtherTestDateStart = '';
    this.covidNeedFurtherTestDateEnd = '';

    this.healthId = '';
    this.additionalHealthId = '';
    this.email = '';
    this.showOnlyDeletedChildren = false;
    this.showOnlyIdConflictedNotFoundChildren = false;
    this.showOnlyImmsConflictedChildren = false;
    this.showOnlySyncProfileChildren = false;

    this.activities = [];
    this.immsQueryBeginDate = '';
    this.immsQueryEndDate = '';
    this.consentTriageStates = [];
    this.vaccineIds = [];
  }


}
