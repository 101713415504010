import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { MarkNotTestedModel } from 'src/app/features/screener/children-page/right-panel/details-panel/tests-list/mark-not-tested-dialog/mark-not-tested.model';
import { VisionTest } from 'src/app/features/screener/children-page/right-panel/details-panel/tests-list/vision-test-details/vision-test.model';
import { VisionTestAdditionalDataConfigModel } from 'src/app/features/screener/vision-test/vision-test-additional-data-config.model';
import { VisionTestAdditionalDataModel } from 'src/app/features/screener/vision-test/vision-test-additional-data.model';
import { ColorTest } from 'src/app/features/screener/color-test/color-test.model';
import { TestTypes } from 'src/app/features/screener/shared/enums';

@Injectable({
  providedIn: ServicesModule,
})
export class HttpTestsService {
  constructor(private http: HttpClient) { }

  getVisionTestDetailsById(visionTestId: string) {
    return this.http.get('api/visiontest/' + visionTestId + '/details');
  }

  getClinicalVisionTestById(clinicalVisionTestId: string) {
    return this.http.get('api/clinical/visiontest/' + clinicalVisionTestId);
  }

  getColorTestDetailsById(colorTestId: string) {
    return this.http.get('api/colourtest/' + colorTestId + '/details');
  }

  getPhotoScreenerTestDetailsById(photoScreenerTestId: string) {
    return this.http.get('api/photoScreenerTest/' + photoScreenerTestId + '/details');
  }

  saveMarkNotTestedTests(markNotTestedTests: MarkNotTestedModel) {
    return this.http.post('api/marknottested/tests', markNotTestedTests);
  }

  saveActivityForTests(markNotTestedTests: MarkNotTestedModel[]) {
    return this.http.post('api/saveactivityfortests/tests', markNotTestedTests);
  }

  removeActivityForTests(markNotTestedTests: MarkNotTestedModel[]) {
    return this.http.post('api/removeactivityfortests/tests', markNotTestedTests);
  }

  getPhotoscreenerTestStatesAndPotentialConditions() {
    return this.http.get('api/photoscreenerTest/test-states/potential-conditions');
  }

  addPhotoscreenerTestManualWelchAllyn(test: any) {
    return this.http.post('api/photoscreenerTest/add/welchallyn/manual', test);
  }

  getVisionTestConfigurationBySchoolId(schoolId: string) {
    return this.http.get('api/visiontests/Configuration/' + schoolId);
  }

  getVisionTestAdditionalDataConfig() {
    return this.http.get<VisionTestAdditionalDataConfigModel>('api/VisionTests/GetVisionTestAdditionalDataConfiguration/');
  }

  saveVisionTest(test: VisionTest, additionalDataAnswers: VisionTestAdditionalDataModel ) {
    return this.http.post('api/visiontests/', { visionTest: test, testAdditionalData: additionalDataAnswers });
  }

  needTrialLicenseWarning() {
    return this.http.get('api/NeedTrialLicenseWarning');
  }

  getColorTestConfigurationBySchoolId(schoolId: string) {
    return this.http.get('api/colorTests/Configuration/' + schoolId);
  }

  saveColorTest(colorTest: ColorTest) {
    return this.http.post('api/colorTests/', colorTest);
  }

  saveGrowthTest(growthTest: any) {
    return this.http.post('api/growthTests/', growthTest);
  }

  getIsLatestTestStateIsNoConsent(testType: TestTypes, childId: string)
  {
    return this.http.get('api/isLastTestStateNoConsent/' + testType + "/" + childId);
  }

}
