export enum TestTypes {
  VisionTest = 0,
  ColourTest = 1,
  GrowthTest = 2,
  HearingTest = 3,
  SpeechSoundTest = 4,
  PhotoscreenerTest = 5,
  Imms = 6,
  HNA = 7,
  Covid = 8
}
