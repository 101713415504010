import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent implements OnInit {

  readonly gdprCookie: string = 'pWrD4jBo';
  readonly gdprValue: string = '~login~';
  gdprNotificationDisplayed: boolean = false;

  constructor(private cookieService: CookieService
  ) { }

  ngOnInit() {
    if (location.href.indexOf("us.schoolscreener.com") == -1) {
      this.gdprCheck();
    }
  }

  gdprSeen() {
    this.writeCookie(this.gdprCookie, this.gdprValue);
    this.gdprNotificationDisplayed = false;
  }

  writeCookie(name, value) {
    this.cookieService.set(name, value, 730);
  }

  gdprCheck() {
    if (this.lookupCookie()) {
      this.gdprNotificationDisplayed = true;
    }
  }

  lookupCookie() {
    var value = this.cookieService.get(this.gdprCookie);
    return !(value !== undefined && value.indexOf(this.gdprValue) != -1);
  }

}
