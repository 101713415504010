<div *ngIf="showGdpr">
  <app-gdpr></app-gdpr>
</div>

<div [ngClass]="isNewTemplate ? 'new-footer' : 'footer'" *ngIf="showFooter">
  <div class="container">
    <div class="links-wrapper">
      <div class="footer-copyright col-md-8 col-sm-12">
        {{brandName}} &reg; {{ 'FooterLoginDescription' | resourcekey }}
      </div>
      <div class="footer-links col-md-4 col-sm-12">
        <!-- <div>
          <a mat-raised-button href="https://parentsupport.schoolscreener.com" class="footer-link footer-button" target="_blank"><b>FAQ</b></a>
        </div> -->
        <div class="flex-container terms">
          <div><a href="{{ 'LoginPrivacyPolicyLink' | resourcekey }}" class="footer-link" target="_blank">{{ 'Common_PrivacyDataProtection' | resourcekey }}</a>&nbsp;|&nbsp;</div>
          <div><a href="{{ 'LoginTermsConditionsLink' | resourcekey }}" class="footer-link" target="_blank">{{ 'Common_TermsOfUse' | resourcekey }}</a></div>

        </div>
      </div>
    </div>
  </div>
</div>
