<h1 mat-dialog-title class="warning-dialog-title">{{ 'Common_Warning' | resourcekey }}!</h1>
<div mat-dialog-content class="warning-dialog-content">
  <div class="warning-icon-container">
    <mat-icon class="material-icons warning-icon">warning</mat-icon>
  </div>
  <p class="inactive">
    {{ 'InactiveTwentyMinutes' | resourcekey }}
  </p>
  <p>
    {{ 'InactiveThirtyMinutes' | resourcekey }}
  </p>
</div>
<div mat-dialog-actions class="action-buttons">
  <button mat-raised-button color="primary" (click)="closeDialog()">{{ 'Common_Ok' | resourcekey }}</button>
</div>
