import { AdditionalFieldValueModel } from ".";
import { AdditionalFieldType } from "../enums";

export class AdditionalFieldModel {
    id: string;
    label: string;
    fieldType: AdditionalFieldType;
    order: number;
    groupCode: string;
    additionalFieldValueModel: AdditionalFieldValueModel;
}