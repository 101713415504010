export class GPDetails {
  id?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  address1: string;
  address2?: string;
  town: string;
  county?: string;
  postCode: string;
  isSelfGPRelation?: boolean;
}
