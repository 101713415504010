import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { CampaignTypeEnum } from '../../../shared/enums/campaign-type.enum';
import { QuestionResult } from '../../../shared/models';
import { QuestionResultsResponse } from 'src/app/shared/models/question-results-response.model';
import { QuestionnaireDetailsParameters } from 'src/app/shared/models/questionnaire-details-parameters.model';

@Injectable({
  providedIn: ServicesModule
})

export class HttpQuestionnaireService {

  constructor(private http: HttpClient) { }

  submitQuestionResults(sourceId: string, schoolId: string, questionResults: QuestionResult[], campaignType: CampaignTypeEnum, sendEmail: boolean = true) {
    return this.http.post('api/questionnaire/submit',
      {
        SourceId: sourceId,
        SchoolId: schoolId,
        Results: questionResults,
        CampaignType: campaignType,
        SendEmail: sendEmail
      });
  }

  submitQuestionWithoutResults(questionResultsResponse: QuestionResultsResponse) {
    return this.http.post('api/questionnaire/submitWithoutResults', questionResultsResponse);
  }

  AddSubmissionWithQuestionResults(childId: string, campaignId: string, schoolId: string, questionResults: QuestionResult[], campaignType: CampaignTypeEnum, sendEmail: boolean = true) {
    return this.http.post('api/questionnaire/add',
      {
        ChildId: childId,
        CampaignId: campaignId,
        SchoolId: schoolId,
        Results: questionResults,
        CampaignType: campaignType,
        SendEmail: sendEmail
      });
  }

  AddTestSubmissionWithQuestionResults(questionResultsResponse: QuestionResultsResponse) {
    return this.http.post('api/questionnaire/test/add/submission', questionResultsResponse);
  }

  saveQuestionResults(sourceId: string, schoolId: string, questionResults: QuestionResult[], campaignType: CampaignTypeEnum, campaignId:string = null, childId:string=null) {
    return this.http.post('api/questionnaire/save',
      {
        SourceId: sourceId,
        SchoolId: schoolId,
        Results: questionResults,
        campaignType: campaignType,
        campaignId: campaignId,
        childId: childId
      });
  }

  getHnaQuestionnaireDetails(sessionId: string) {
    return this.http.get('api/hnaTest/getHnaQuestionnaireDetails/' + sessionId);
  }

  getCampaignQuestionnaire(sourceId: string, sessionId: string) {
    return this.http.get('api/hnaTest/getCampaignQuestionnaire/' + sourceId + '/' + sessionId);
  }

  getQuestionnaireWithResults(campaignId: string, submissionId: string) {
    return this.http.get('api/getQuestionnaireWithResults/' + campaignId + '/' + submissionId);
  }

  getEmptyQuestionnaire(campaignId: string) {
    return this.http.get('api/getEmptyQuestionnaire/' + campaignId);
  }

  getQuestionnaireWithoutImagesIncludingResults(submissionId: string, campaignId: string) {
    return this.http.get('api/getQuestionnaireWithResultsWithoutImages/' + campaignId + '/' + submissionId);
  }

  GetHNAReadOnlyQuestionnaireBySubmissionId(submissionId: string) {
    return this.http.get('api/hnaquestionnaire/submission/getQuestionnaireWithResults/' + submissionId);
  }

  unlinkSubmission(submissionId: string, campaignType: number) {
    return this.http.post('api/form-manager/unlink-submission',
      {
        SubmissionId: submissionId,
        CampaignType: campaignType
      }
    );
  }

  getReadOnlyQuestionnaire(submissionId: string, campaignType: CampaignTypeEnum) {
    return this.http.get('api/view/questionnaire/' + submissionId + '/' + campaignType);
  }

  getReadOnlyAdministrationQuestionnaires(submissionId: string) {
    return this.http.get('api/view/administration/questionnaires/' + submissionId);
  }

  getTenantHealthIdVerificationByCampaignId(campaignId: string){
    return this.http.get('api/getTenantHealthIdVerificationByCampaignId/' + campaignId);
  }

  getTenantHealthIdVerificationByCampaignPortalCode(campaignPortalCode: string){
    return this.http.get('api/getTenantHealthIdVerificationByCampaignPortalCode/' + campaignPortalCode);
  }

  getTenantCountryCodeByCampaignId(campaignId: string){
    return this.http.get('api/getTenantCountryCodeByCampaignId/' + campaignId);
  }

  getTenantCountryCodeByCampaignCode(campaignCode: string){
    return this.http.get('api/getTenantCountryCodeByCampaignCode/' + campaignCode);
  }

  getTenantCountryCodeByTenantCode(tenantCode: string){
    return this.http.get('api/getTenantCountryCodeByTenantCode/' + tenantCode);
  }

  getQustionnaireSectionsWithCampaignDetails(questionnaireDetailsParameters:QuestionnaireDetailsParameters){
    return this.http.post('api/questionnaire/getQustionnaireSectionsWithCampaignDetails/', questionnaireDetailsParameters);
  }
}
