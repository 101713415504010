<h1 *ngIf="!data.isOrange" mat-dialog-title class="dialog-title">{{ data.title }}</h1>
<div *ngIf="!data.isHtml" mat-dialog-content [ngClass]="data.isOrange ? 'content-orange' : 'dialog-content'">
  <p>{{ data.message }}</p>
</div>
<div *ngIf="data.isHtml" mat-dialog-content class="dialog-content" [innerHTML]="data.message">
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button [ngClass]="data.isOrange ? 'button-cancel-orange' : ''" (click)="onCancel()">{{data.cancelButton || ('Common_No' | resourcekey)}}</button>
  <button mat-raised-button [ngClass]="data.isOrange ? 'button-confirm-orange' : ''" color="primary" (click)="onConfirm()">{{data.confirmButton || ('Common_Yes' | resourcekey)}}</button>
</div>
