import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { InactiveUserDialogComponent } from './inactive-user-dialog/inactive-user-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SharedPipesModule } from "../pipes/shared-pipes.module";

@NgModule({
    declarations: [
        InactiveUserDialogComponent,
    ],
    exports: [
        InactiveUserDialogComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        SharedPipesModule
    ]
})
export class GlobalComponentsModule { }
