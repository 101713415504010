import { GenderEnum } from '../enums/gender.enum';
import { VaccineAdministrationStatesEnum } from '../enums/vaccine-administration-states.enum';
import { HNAStatesEnum } from '../enums/hna-states.enum';
import { TestTypes } from 'src/app/features/screener/shared/enums/test-types.enum';
import { KeyValuePair } from './key-value-pair.model';
import { IdConfirmationEnum } from '../enums/id-confirmation.enum';
import { EnvironmentSourceEnum } from '../enums/environment-source.enum';

export class Child {
  id: string;
  tenantId: string;
  schoolId: string;
  schoolName: string;
  class: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: GenderEnum;
  cohort: string;
  yearGroup: string;
  email: string;
  vaccineAdministrationResult?: VaccineAdministrationStatesEnum;
  vaccineAdministrationResultDisplayName: string;
  vaccineAdministrationCampaignId: string;
  ethnicity: string;
  ethnicityDisplayName: string;
  nhs: number;
  additionalId: number;
  address1: string;
  address2: string;
  town: string;
  county: string;
  postcode: string;
  primaryCarerName: string;
  primaryCarerEmail: string;
  primaryCarerPhone: string;
  doctorName: string;
  doctorEmail: string;
  doctorPhone: string;
  fullName: string;
  hnaCampaignId?: string;
  hnaResult?: HNAStatesEnum;
  hnaResultDisplayName?: string;
  testResults: KeyValuePair<KeyValuePair<TestResult>>;
  isAdult?: boolean;
  phone?: string;
  country?: string;
  occupationId?: string;
  childKnownAs?: string;
  notes?: string;
  operationDetails?: string;
  idConfirmation: IdConfirmationEnum;
  idConfirmationText: string;
  isDeleted: boolean;
  modifiedByFullName: string;
  modifiedOn: Date;
  modifiedEnv: EnvironmentSourceEnum;
  createdByFullName: string;
  createdOn: Date;
  createdEnv: EnvironmentSourceEnum;
}

export class TestResult {
  testType: TestTypes;
  result: number;
  displayName: string;
}
