import { Pipe, PipeTransform } from '@angular/core';
import { ResourceService } from 'src/app/core/services';

@Pipe({
  name: 'resourcekey'
})
export class ResourcekeyPipe implements PipeTransform {
  constructor(private resourceService: ResourceService) { }

  transform(value: string): any {
    return this.resourceService.getResourceByKey(value);
  }
}
