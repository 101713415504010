import { KeyValuePair } from 'src/app/shared/models';
import { TestTypes } from '../../features/screener/shared/enums/test-types.enum';
import { NotTestedReasonItem } from './not-tested-reason-item.model';

export class ColumnDetails{
  label: string;
  columnName: string;
  testType: TestTypes;
  filter: KeyValuePair<string>[];
  filterText: string;
  notTestedReasons: NotTestedReasonItem[];
  testedReasons: NotTestedReasonItem[];
}
