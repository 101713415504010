import { Injectable } from '@angular/core';
import { SessionUser } from '../../shared/models';
import { TestAvailabilityEnum } from '../../shared/enums/test-availability.enum';

@Injectable({
  providedIn: 'root'
})
export class FeatureAvailabilityService {
  hasImmunisationAvailable(): boolean {
    return this.getUser().immunisationAvailability == TestAvailabilityEnum.OnlineAndOffline || this.getUser().immunisationAvailability == TestAvailabilityEnum.OnlineOnly;
  }

  hasHealthNeedsAssessmentAvailable(): boolean {
    return this.getUser().healthNeedsAssessmentAvailability == TestAvailabilityEnum.OnlineAndOffline || this.getUser().healthNeedsAssessmentAvailability == TestAvailabilityEnum.OnlineOnly;
  }

  hasCovidTestAvailable(): boolean {
    return this.getUser().covidTestManagerAvailability == TestAvailabilityEnum.OnlineAndOffline || this.getUser().covidTestManagerAvailability == TestAvailabilityEnum.OnlineOnly;
  }

  private getUser(): SessionUser {
    return JSON.parse(sessionStorage.getItem('currentUser')) as SessionUser;
  }
}
