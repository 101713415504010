import { QuestionTypeEnum } from '../enums/question-type.enum';
import { QuestionOptions } from '.';
import { QuestionResult } from './question-result.model';
import { QuestionOptionTitleTypeEnum } from '../enums/question-option-title-type.enum';
import { QuestionDateOptionEnum } from '../enums/question-date-option-type.enum';
import { linkedQuestionOptionIdList } from './linkedQuestionOptionIdList.model';

export class Question {
  id: string;
  inputType: QuestionTypeEnum;
  isRequired: boolean;
  order: number;
  title?: string;
  placeholder?: string;
  description?: string;
  questionOptions?: QuestionOptions[];
  askCommentIfOption?: any;
  commentLabel?: string;
  questionResult?: QuestionResult;
  linkedQuestionOptionId?: string;
  isVisible?: boolean;
  isRelated?: boolean;
  relatedQuestionId?: string;
  relatedQuestionIdList?: string[] = [];
  questionImage: any;
  decimalDigit?: number;
  minValue?: number;
  maxValue?: number;
  defaultValue?: number;
  questionOptionTitleType?: QuestionOptionTitleTypeEnum;
  questionDateOptionType?: QuestionDateOptionEnum;
  linkedQuestionOptionIdList?: linkedQuestionOptionIdList[] = [];

  constructor(options: Question) {
    this.id = options.id;
    this.inputType = options.inputType;
    this.title = options.title;
    this.description = options.description;
    this.isRequired = options.isRequired;
    this.order = options.order;
    this.questionOptions = options.questionOptions;
    this.askCommentIfOption = options.askCommentIfOption;
    this.commentLabel = options.commentLabel;
    this.questionResult = options.questionResult;
    this.linkedQuestionOptionId = options.linkedQuestionOptionId;
    this.linkedQuestionOptionIdList = options.linkedQuestionOptionIdList;

  }
}
