import { UserColumnVisibilityType } from '../enums/user-column-visibility-enum';

export class UserColumnVisibility {
  id: string;
  class: boolean;
  firstName: boolean;
  lastName: boolean;
  dateOfBirth: boolean;
  gender: boolean;
  visionTest: boolean;
  colorTest: boolean;
  growthTest: boolean;
  photoScreener: boolean;
  speechSoundTest: boolean;
  hearingTest: boolean;
  immunisation: boolean;
  hna: boolean;
  covid: boolean;
  yearGroup: boolean;
  type: UserColumnVisibilityType;
}
