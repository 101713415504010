import { Component, OnInit, Input } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AuthenticationService, HttpCoreService, ResourceService } from "src/app/core/services";
import { ExportPdfService } from "src/app/core/services/export-pdf.service";
import { Letter } from "src/app/features/screener/shared/models";
import { StringFormatPipe } from "../../pipes";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { formatDate } from "@angular/common";
import { FilePreviewComponent } from "../file-preview";
import { SharedDocumentUpload } from "src/app/features/screener/shared/models/shared-document-upload.model";
@Component({
  selector: "message-preview",
  templateUrl: "./message-preview.component.html",
  styleUrls: ["./message-preview.component.scss"]
})



export class MessagePreviewComponent implements OnInit {
  @Input() message?: Letter;
  @Input() content: any;
  @Input() title: string;
  @Input() sharedDocumentSourceId: string;
  @Input() isFromNotification: boolean = false;

  sharedDocumentUploads: SharedDocumentUpload[] = [];



  previewHTML: SafeHtml;
  private culture: string;
  deleteDialogRef: MatDialogRef<ConfirmDialogComponent>

  constructor(private domSanitizer: DomSanitizer,
    private httpCoreService: HttpCoreService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private resourceService: ResourceService,
    private stringFormatPipe: StringFormatPipe,
    private exportPdfService: ExportPdfService,
    private parentDialogRef: MatDialogRef<FilePreviewComponent>) {
    this.culture = this.authenticationService.currentUserValue.userCulture;
  }

  ngOnInit() {
    this.initMessage();
  }

  initMessage() {
    this.previewHTML = this.domSanitizer.bypassSecurityTrustHtml(this.content);
    this.httpCoreService.services.user.getSharedDocumentUploads(this.sharedDocumentSourceId)
      .subscribe((data: any) => {
        this.sharedDocumentUploads = data;
      });
  }

  downloadSharedDocument(sharedDocumentUploadId: string, fileName: string) {
    this.httpCoreService.services.screener.downloadSharedDocumentUpload(sharedDocumentUploadId)
      .subscribe((data: any) => {
        let fileNameStr = this.exportPdfService.getBulkPDFName(fileName);

        this.exportPdfService.downloadFile(data, fileNameStr);
      });
  }

  deleteReportHistory(letter: Letter, allDelete) {
    let createdOn = formatDate(letter.createdOn, 'dd-MM-yyyy hh:mm:ss', this.culture);
    if(!allDelete) {
      if(letter.childMessage.messageSourceCount == 1) {
        allDelete = true;
      }
    }
    this.deleteDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "575px",
      data: {
        title: this.resourceService.getResourceByKey('DeleteMessageDocument'),
        message: allDelete ?
        this.stringFormatPipe.transform(
          this.resourceService.getResourceByKey(
            "DeleteMessageText"
          ),
          [letter.letterType, letter.childMessage?.messageSourceCount]
        ) :
        this.stringFormatPipe.transform(
          this.resourceService.getResourceByKey(
            "ReportHistory_DeleteMessage"
          ),
          [letter.letterType, letter.screenerInitials, createdOn]
        )
      },
      autoFocus: false,
      panelClass: "base-dialog",
    });

    this.deleteDialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.httpCoreService.services.screener.deleteSharedDocumentSource(letter?.childMessage.sharedDocumentId, letter.childId, allDelete)
          .subscribe(() => {
            this.parentDialogRef.close();
          });
      }
    });
  }
}
