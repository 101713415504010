import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDialogComponent } from './loading-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { LoadingModule } from '../loading/loading.module';



@NgModule({
  declarations: [LoadingDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    LoadingModule
  ]
})
export class LoadingDialogModule { }
