import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppComponent } from "./app.component";
import {
  LocationStrategy,
  HashLocationStrategy
} from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RoutingModule } from "./routing/routing.module";
import { CoreModule } from "./core/core.module";
import { HeaderComponent } from "./core/header/header.component";
import { FooterComponent } from "./core/footer/footer.component";
import { HeaderService } from "./core/services/header.service";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { ResourcesResolve } from "./routing/resources.resolve";
import { ResourceService } from "./core/services";
import { GlobalComponentsModule } from "./shared/components/global-components.module";
import { LogoHnaResolve } from "./routing/logo-hna.resolve";
import { LogoImmsResolve } from "./routing/logo-imms.resolve";
import { LogoScreenerResolve } from "./routing/logo-screener.resolve";
import { EnvironmentConfigsLoaderService } from "./core/services/environment-configs-loader.service";
import { LoadingDialogModule } from './shared/components/loading-dialog/loading-dialog.module';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  MicrosoftLoginProvider
} from 'angularx-social-login'
import { MatBadgeModule } from "@angular/material/badge";
import { MatMenuModule } from "@angular/material/menu";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedPipesModule } from "./shared/pipes/shared-pipes.module";
import { SharedComponentsModule } from "./shared/components/shared-components.module";
import { LogoClinicianResolve } from "./routing/logo-clinician.resolve";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    RoutingModule,
    CoreModule,
    GlobalComponentsModule,
    LoadingDialogModule,
    SocialLoginModule,
    MatBadgeModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    SharedPipesModule,
    SharedComponentsModule,
    MatInputModule,
    MatTabsModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [EnvironmentConfigsLoaderService],
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('815089019493313')
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider('83d62e58-8ded-42bf-b603-078be94a591d')
          }
        ],
        onError: (err) => {
        }
      } as SocialAuthServiceConfig,
    },
    HeaderService,
    ResourcesResolve,
    LogoHnaResolve,
    LogoImmsResolve,
    LogoScreenerResolve,
    ResourceService,
    LogoClinicianResolve
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function appInitializerFactory(
  configsLoaderService: EnvironmentConfigsLoaderService
) {
  return () => configsLoaderService.loadConfigs();
}
