import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  downloadFile(fileName: string, fileExtension: string, content: Blob) {
    let url = URL.createObjectURL(content);

    let downloadLink = document.createElement("a");
    downloadLink.setAttribute("href", url);
    downloadLink.setAttribute("download", fileName + "." + fileExtension);
    downloadLink.style.visibility = "hidden";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  downloadBlobFile(response: HttpResponse<Blob>): void {
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    const fileName = contentDispositionHeader.split(';')[1].split('=')[1].replace(/"/g, '');
    const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  convertDataToBinary(dataURI: string): Uint8Array {
    var raw = window.atob(dataURI);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }
}
