import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function CheckWhitespace(inputControl: FormControl): ValidationErrors | null {
  if (inputControl.value && inputControl.value.length > 0) {
    return inputControl.value.trim() ? null : { whitespace: true };
  }

  return null;
}

export function CheckWhitespaceValidatorMessage(err, field: FormlyFieldConfig): string {
  return `${field.templateOptions.label} is cannot be blank`;
}
